.font-selector {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    
    width: 80%;
  }
  
  .font-option {
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .font-option:hover {
    border-color: #555;
  }
  
  .font-option.selected {
    border-color: #555;
  }
  