.features-carousel {
    display: flex;
    flex-direction: column; /* Change to column to stack elements vertically */
    align-items: center; /* Center the children horizontally */
    overflow: hidden;
  }
  
  .arrow-buttons {
    display: flex;
    gap: 10px;
  }
  
  .arrow-button {
    background-color: rgb(19, 2, 48);
    border: none;
    color: rgb(224, 102, 210);
    text-align: center;
    text-decoration: none;
    display: flex;
    /* Changed to flex */
    align-items: center;
    /* Added for vertical alignment */
    justify-content: center;
    /* Added for horizontal alignment */
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 25px;
    font-weight: bold;
    /* Adjust this value as needed */
    font-family: "Poppins", sans-serif;
  }
  
  .arrow-button:hover {
    background-color: rgb(51, 51, 51);
  }
  
  
  
  .features-container {
    flex: 1; /* Take up all available space */
    overflow: hidden; /* Hide overflowing content */
    margin-bottom: 0;
  }
  
  .features-row {
    display: flex;
    transition: transform 0.3s ease-in-out;
    padding: 8px;
  }
  
  .feature-item {
    flex: 1;
    min-width: 20%;
    box-sizing: border-box;
    padding: 16px;
    text-align: center;
    overflow: hidden;
    position: relative;  /* Add this line */
    background-color: #1f0034
  }
  
  .feature-icon {
    font-size: 2rem;
  }
  
  .carousel-title {
    color: rgb(19,2,48);
    font-size: 2.5rem;
    /* Add any other styling you want for the title */
  }
  
  .highlight {
    color: rgb(224,102,210);
    /* Add any other styling you want for the highlighted text */
  }
  

  /* Mobile Styles (up to 768px) */
@media (max-width: 768px) {
    
  .features-carousel {
      padding: 10px 0; /* Add some vertical padding for better spacing */
  }

  .carousel-title {
      font-size: 1.8rem; /* Reduce the size to fit mobile screens */
      text-align: center; /* Centered text looks good on mobile */
      margin-bottom: 20px; /* Spacing between title and carousel content */
  }
  
  .features-row {
      flex-direction: column; /* Stack features vertically on mobile */
  }
  
  .feature-item {
      min-width: 100%; /* Each feature takes the full width on mobile */
      margin-bottom: 20px; /* Space between each feature */
  }
  
  .feature-icon {
      font-size: 1.8rem; /* Slightly reduce icon size for mobile */
      margin-bottom: 15px; /* Some space between icon and feature title */
  }
  
  .arrow-buttons {
     display: none; /* Space between the carousel and the arrows */
  }

  
  /* In case the text content is a bit large, adjust font sizes here */
  .feature-item h3 {
      font-size: 1.2rem; /* Adjust the feature title font size */
  }

  .feature-item p {
      font-size: 1rem; /* Adjust the feature content font size */
  }
}
