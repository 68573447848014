.VideoPlayer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* for 16:9 aspect ratio */

}

.VideoPlayer video {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 50%;
}




.play-pause-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Align items vertically in the center */
  margin-bottom: 0px;
  margin-top: 10px;
}

.play-pause-btn {
  background: rgb(54, 11, 72);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border: transparent;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex; /* Use flexbox to center the icon */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  height: 50px; /* Adjust height as needed */
  width: 50px; /* Adjust width as needed */
}


.expand-btn {
  background: rgb(54, 11, 72);
  border: none;
  color: white;
  height: 30px; /* Set a fixed height for the button */
  width: 30px; /* Set a fixed width to make it square */
  cursor: pointer;
  border: transparent;
  border-radius: 5px;
  position: relative;
  margin: 0 20px; /* Horizontal margins to create space between buttons */
  margin-bottom: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex; /* Use flexbox to center the icon */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
}

.expand-time-text {
  color: grey;
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
}

.expand-container {
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  align-items: center;
  /* Horizontally center the children */
}



button:focus {
  outline: none;
}



.Progress {
  background: white;
  flex-basis: 0;
}

/* Same as previous */

/* Slider */
.trim-slider {
  width: 100%;
  height: 25px;
  background: #ddd;
  outline: none;
}

/* Thumb */
.thumb {
  width: 2px;
  height: 100%;
  background: transparent;
  cursor: grabbing;
  outline: none;
}

/* Track */
.track {
  top: 0;
  bottom: 0;
  background: #000;
}

.selected-track {
  top: 0;
  bottom: 0;
  background: #000;
  border: 4px solid rgba(26, 104, 228, 0.7);
  /* Add border */
}

/* Start and end lines */
.start-line,
.end-line {
  width: 4px;
  height: 100%;
  background: rgba(226, 104, 228, 0.7);
  position: relative;
}

.start-line::before,
.end-line::before {
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
  border: 1.5px solid rgba(226, 104, 228, 0.7);
}

.trim-controls {
  position: relative;
  height: 25px;
  width: 100%;
}

.progress-bar-container {
  height: 5px;
  width: 100%;
  background: rgb(227, 214, 240);
}

.progress-bar {
  height: 100%;
  background: rgb(54, 11, 72);

}

.current-playtime-button {
  position: absolute;
  height: 30px;
  width: 3px;
  background-color: #420c68;
  z-index: 2;
}

.playhead-container {
  position: absolute;
  height: 25px;
  /* adjust to the height of your slider */
  top: 0;
}

.playhead {
  position: absolute;
  height: 30px;
  /* adjust to the height of your slider */
  width: 2px;
  background-color: rgb(21, 0, 21);
  z-index: 3;
  background-color: rgba(54, 11, 72, 0.5);
  z-index: 10;
}

.ProgressBar {
  /* existing styles... */
  padding-bottom: 20px;
  /* Add padding below the progress bar */
}

.handle {
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;


}

.slider-tracks div {
  /* existing styles */
  height: 30px;
  /* ... */
}

.playhead-timestamp {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 5px;
  background-color: #420c68;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-family: Poppins;
}

.playhead-line {
  position: absolute;
  width: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #420c68;
  transition: height 0.3s;
}

.playhead-line:hover {
  height: 30px;
}

.playhead-timestamp.negative-time {
  width: 60px;
  /* Adjust as needed */
  text-align: center;
}


