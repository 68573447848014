@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

/*html {
  background-color: rgba(56, 2, 69);
}*/
* {
  box-sizing: border-box;
  
}

body {
    overflow-x: hidden;
}

@keyframes glideIn {
    from {
        transform: translateY(100px); /* starting position */
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.text-container {
    /* Add the glide-in animation */
    animation: glideIn 1s forwards;
    text-align: left;
}

#tsparticles canvas {
    z-index: 10 !important;
  }
  


#tsparticles {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; 
}

.duotone {
    position: relative;
    color: rgb(224, 102, 210); /* Your primary color */
    

    /* This creates a duplicate of the text with a slightly offset position and different color */
}




/* This will be the main container that holds everything and centers it on the page */
.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 100%;
}

/* This will stack the logos one above the other */
.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the logos horizontally */
    gap: 3px; /* Adjust if you want more or less space between the logos */
}

/* This will stack the texts one above the other */
.text-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the texts horizontally */
    text-align: left;
    margin: 0 auto;
    width: 100%;
    padding: 0 15%;  /* Padding on each side */
}

  
  
  .stacked-logo {
    position: absolute;
    top: 15vh;
    width: 10vw;
    z-index: 0;

    /* No need for z-index here since we want to test stacking */
  }

  .stacked-logo {
    transition: filter 0.3s ease; /* for a smoother shadow transition if needed */
}

  
  .stacked-logo.logo1 {
    z-index: 1;
}

.stacked-logo.logo2 {
    z-index: 0;
}

.stacked-logo.logo3 {
    z-index: 0;
}


.air-text {
    margin-top: 0;
    font-size: 4rem;       /* Adjust based on desired sizing */
    margin-bottom: 0px;   /* Pushes the AI-powered text down for separation */
}




.air-text {

    color: #d8caf9;
}

.ai-powered-text {
    font-size: 1.5rem;
    color: #d8caf9;
    font-weight: bold;
    width: 75%;
}

.btn-signup {
    background-color: rgb(241, 92, 216);
    color: rgb(21,1,51);
    margin-top: 10%;
    padding: 12px 24px;
    border-radius: 20px; /* Rounded corners */
    border: none;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s; /* For smooth transition on click and hover */
    font-weight: 600; /* Slightly bolder text */
    font-size: 1rem;
    outline: none; /* Removes focus outline */
}

/* Click effect: scales the button slightly */
.btn-signup:active {
    transform: scale(0.95);
}

/* Hover effect: a subtle shadow for depth */

.btn-signup:hover {
    background-color: rgb(209,109,205);
    border-radius: 25px;
    transform: scale(0.95);
  }


  /* Mobile Styles (up to 768px, which usually covers mobile devices) */
@media (max-width: 768px) {

    

    .main-container {
        padding: 15px; /* Add some space on mobile for breathing room */
        width: 100%;
    }

    .text-container h1.air-text {
        font-size: 1.8rem; /* Make the main header smaller */
        line-height: 2.2rem; /* Adjusting line height for readability */
        align-items: center;
    }

    .text-container h1.ai-powered-text {
        display: none;
    }

    .btn-signup-link {
        display: block; /* Full width button */
        margin-top: 20px; /* Some space above the button */
    }

    .btn-signup {
        width: 100%; /* Full width button */
        padding: 12px; /* More padding for a bigger touch area */
        border: none; /* Removing border */
        font-size: 1rem; /* Adjusting font size */
    }


    /* Starfield can stay as it's a background effect */
    
    /* If you wish to adjust or hide the logos, you can add their styles here */
    /* .logo-container img {
        width: 70%; Adjust logo width
    } */
}



/* .landing {
  background-color: rgba(56, 2, 69);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 0;
} */

/* 

.land-back {
  background-color: rgba(56, 2, 69);
} */
 

.landing {
  max-width: 1400px; /* Adjust this value based on your preference */
  margin: 0 auto; /* This centers the element horizontally */
}


.bottom-section {
  width: 90%;
  
}





.top-section::before, .top-section::after {
  content: '';
  position: absolute;
  width: 100px; /* Adjust based on desired blur size. */
  height: 100px; /* Adjust based on desired blur size. */
  border-radius: 50%; /* Make it circular. */
  top: calc(8%); /* Adjust for positioning. */
  left: calc(30%); /* Adjust for positioning. */
  background: radial-gradient(circle, rgba(228, 26, 228, 0.6), rgba(90, 14, 128, 0.8));
  filter: blur(50px); /* Add blur. */
  z-index: -1; /* Ensure blurs appear behind any other content. */
}

.top-section::after {
  top: calc(5%);
  left: calc(90%);
  background: radial-gradient(circle, rgba(228, 26, 228, 0.6), rgba(60, 8, 98, 0.8));
}










.main-title {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 2px;
  margin-bottom: 10px;
  text-align: left;
  
}
.main-title h1 {
  z-index: 1;
  color: #e3dbf5;
  font-size: 3rem;
  font-weight: bolder;
  margin: 0;
  display: inline-block;
  text-align: right;
}

.main-title .highlight {
  font-size: 3rem;
  background-color: rgb(148, 38, 191);
  color: #e3dbf5;
  padding: 3px 8px;
  border-radius: 10px;
  animation: highlightText 1s ease-out 1;
  box-shadow: 3px 1px 2px black;
}

@keyframes highlightText {
  0% {
    background-color: transparent;
    color: #000;
  }
  100% {
    background-color: rgb(148, 38, 191);
    color: #e3dbf5;
  }
}

.features-showcase {
  margin-top: 5rem;
  width: 100%;
}

.feature-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.feature-navigation {
  flex-direction: column;
  align-items: stretch; /* This will make sure that children stretch the full width of the container */
}


.feature-name {
  cursor: pointer;
  padding: 10px;
  transition: background 0.7s;
}

.feature-text {
  font-weight: bold;
}

.carousel-container {
  overflow: hidden;
}

.carousel-nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0; 
}


.carousel-wrapper {
  display: flex;
  transition: transform 0.7s ease;
  /* This transform value needs to be dynamic based on currentFeature state */
  /* For example: transform: translateX(-${currentFeature * 100}%) */
}

.carousel-nav-title.active {
  color: #be41e3; /* Your primary purple color */
}

.carousel-nav-title {
  color: #e3dbf5; /* Your primary purple color */
  font-size: 1.5rem; /* Adjust as per your preference */
  font-weight: bold;
  margin: 0 20px; /* Add space between each title */
  cursor: pointer; /* Indicate it's clickable */
  transition: color 0.7s ease; /* Smooth transition for color changes */
}

.carousel-nav-title:hover {
  color: #860bac; /* Your primary purple color */
}




.carousel-item {
  flex: 0 0 100%; /* This ensures each item takes the full width of the container and doesn't grow or shrink */
  overflow: hidden; /* To ensure nothing inside the item bleeds out */
  transition: transform 0.7s ease-out;
}



.carousel-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.features-showcase h2 {
    font-size: 3.5rem;
    background-color: transparent;
    color: #e3dbf5;
    padding: 3px 10px;
    border-radius: 10px;
    text-align: center;
}

.feature-description {
  flex: 1;
  margin-right: 20px; /* Creates a gap between the text and the image */
}

.feature-description h2 {
  font-size: 3.5rem; /* Adjust this according to your design preference */
  color: #e3dbf5; /* Your primary purple color */
  margin-bottom: 10px; 
  text-align: left;
}

.feature-description p {
  font-size: 1.3rem; /* Or whatever size you find appropriate */
  color: #e3dbf5;
}

.feature-image {
  flex: 1;
  text-align: right;
}

.feature-image img {
  max-width: auto;
  height: auto;
  border-radius: 10px; /* Optional: can make the image corners slightly rounded */
}

.indicator-bar-wrapper {
  height: 4px; /* Adjust the height as you like */
  background-color: rgba(221, 159, 229, 0.844); /* A subtle background using your shades of purple */
  margin-top: 10px; /* Some space from the titles */
  position: relative; /* To position the active-indicator */
  border-radius: 2px; /* Optional: to make the edges smoother */
}

.active-indicator {
  height: 4px; 
  background-color: #e3dbf5; /* Your primary purple color for the active part */
  position: absolute; 
  top: 0; 
  left: 0;
  transition: width 0.7s ease; /* For a smooth transition between slides */
}

.nav-titles {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.pricing-section {
  background-color: transparent;
  border-radius: 25px;
  width: 100%;
  margin: 2rem 0;
  padding: 2rem;
  
  position: relative;
  z-index: 1;
}

.pricing-section h2 {
  font-size: 3.5rem;
  color: #e3dbf5;
  text-align: center;
  margin-bottom: 1rem;
}

.pricing-tagline {
  font-size: 1.2rem;
  color: #e3dbf5;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
}

.pricing-grid {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: center;
}

.pricing-plan {
  background-color: #1f0034;
  border: 1px solid #1f0034;
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease-in-out; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pricing-plan:hover {
  transform: scale(1.01);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.pricing-plan-header h3 {
  color: #e3dbf5;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.pricing-plan-price {
  color: #e3dbf5;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.pricing-plan-features ul {
  list-style: none;
  padding: 0;
}

.pricing-plan-features li {
  font-size: 1.2rem;
  font-weight: bold;
  color: #e3dbf5;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}





.faq-body {
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}


.faq-item.faq-open .faq-answer {
  display: block;
}

.faq-item {
  position: relative; /* Required for positioning the arrow */
}

.faq-question {
  display: flex; /* Flexbox layout to align the arrow and question */
  justify-content: space-between; /* Space between question and arrow */
}

.arrow {
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #e3dbf5;
  border-bottom: 2px solid #e3dbf5;
  transform: rotate(135deg); /* Facing down */
  transition: transform 0.3s ease;
}

.arrow.up {
  transform: rotate(-45deg); /* Facing up */
}




@keyframes glide-in {
  0% {
    opacity: 0;
    transform: translateY(50px); /* Starting position */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Ending position */
  }
}

.glide-in {
  animation: glide-in 1s ease-out both; /* 1s duration, ease-out timing function */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.beta-section {
  background: transparent;
}

.more-features {
  padding: 2rem;
  text-align: center;
}

.more-features h2 {
  font-size: 3rem;
  color: #dcd0f8;
  margin-bottom: 2rem;
}

.features-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
}

.feature-item {
  flex: 1;
  background-color: #13012c;
  background: linear-gradient(135deg, #13012c, #170137);
  border-radius: 30px;
  padding: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.feature-item h3 {
  font-size: 1.5rem;
  color: #ebe6ff;
  margin-bottom: 1rem;
}

.feature-item p {
  color: #d8caf9;
  font-size: 1rem;
}

.feature-icon {
  font-size: 24px; /* adjust size as needed */
  margin-bottom: 1rem; /* space between the icon and the description */
  color: #ebe6ff; /* adjust color as needed */
}

.feature-icon { /* Assuming this class is added to your icons */
  transition: transform 0.3s ease;
}

.feature-item:hover .feature-icon {
  transform: rotate(15deg); /* Gently rotates the icon */
}

.highlight {
  color: rgb(241, 92, 216);  /* Or whichever shade of pink you prefer */
}




/* ---------------------------------------------------------------------------------- */
/* ---------------------------------MEDIA QUERIES ----------------------------------- */
/* ---------------------------------------------------------------------------------- */

@media (max-width: 768px) {
  .feature-navigation {
    flex-direction: column;
    align-items: center; /* Center the titles */
  }

  .carousel-nav-title {
    font-size: 1.2rem; /* Slightly smaller font */
    margin: 10px; /* Reduce the margin for better spacing on mobile */
  }

  .features-showcase h2 {
    font-size: 2.5rem; /* Reduce the main title size */
  }
  .feature-description {
    margin-right: 0; /* Since it's stacked, no need for right margin */
    text-align: center; /* Centered text */
  }

  .feature-description h2 {
    font-size: 1.5rem;
    text-align: center; /* Adjust title size for mobile */
  }

  .feature-description p {
    font-size: 0.9rem; /* Adjust content size for mobile */
  }

  .feature-image {
    text-align: center; /* Center the image */
    margin-top: 20px; /* Some spacing between description and image */
  }

  .indicator-bar-wrapper {
    width: 90%; /* Make it slightly slimmer */
    margin-left: auto;
    margin-right: auto;
  }
}

/* Mobile Styles */
@media (max-width: 767px) {
  .carousel-container {
    overflow: visible; /* Allow the items to be visible outside the container */
  }

  .carousel-wrapper {
    display: flex; /* Arrange items in a column */
    flex-direction: column;
    transform: none !important; /* Override the transform property to show all items */
  }

  .carousel-item {
    width: 100%; /* Each item takes up the full width of the container */
    flex: 1; /* Allow items to grow to fill available space */
  }
  
  /* Hide the navigation and indicator bar */
  .feature-navigation {
    display: none;
  }

  

  .feature-image {
    display: none;
  }
}

/* Mobile Styles */
@media (max-width: 767px) {
  .more-features .features-row {
    flex-direction: column; /* Stack the feature items vertically */
    align-items: center; /* Center the items if needed */
  }
  
  .more-features .feature-item {
    width: 100%; /* Take up the full width */
    margin-bottom: 20px; /* Add some spacing between items */
  }

  .more-features h2 {
    font-size: 16pt;
  }
}




@media screen and (max-width: 768px) {
  .landing-text,
  .additional-text {
  top: 35rem;
  }
  
  .landing-text h1,
  .additional-text h2 {
  font-size: 2.5rem;
  }
  
  .landing-text p,
  .additional-text p {
  font-size: 1.25rem;
  }

  
  
  .main-title {
    padding: 10px 10px; /* Adjusted padding for smaller screens */
    margin-bottom: 10px; /* Reduced margin for smaller screens */
    text-align: center;

  }

  .main-title h1 {
    font-size: 1rem; /* Reduced font-size for smaller screens */
    line-height: 1.5; /* Added line-height for better text spacing */
    white-space: normal;
    overflow-wrap: normal; /* Added overflow-wrap property to wrap text */
  }
  
  .video-section {
    display: none; /* Hide the video on mobile */
  }

  .main-title .line1,
  .main-title .line2,
  .main-title .highlight {
    font-size: 34px; /* Adjust this to your preferred size */
    line-height: 1.4; /* Adjust spacing between lines */
    margin: 0; /* Remove extra spacing between lines */
    display: flex;
    text-align: center;
  }

  .rounded-button {
  width: 250px;
  height: 300px;
  }
  
  .rounded-button-plus {
  font-size: 2rem;
  }
  
  .rounded-button-logo {
  width: 10rem;
  }
  
  .features-showcase{
    margin-top: 10px;
  }

  .features-section,
  .pricing-section {
  padding: 1rem;
  }
  
  .features-grid,
  .pricing-grid {
  flex-direction: column;
  }
  
  .feature {
  flex-direction: column;
  }
  
  .feature-content,
  .feature-icon {
  flex: 0 0 100%;
  }
  
  .feature:nth-child(even) .feature-content {
  padding-left: 0;
  order: 0;
  }
  
  .feature:nth-child(even) .feature-icon {
  padding-right: 0;
  order: 1;
  }
  
  .feature-image {
  width: 80%;
  }
  
  .pricing-plan {
  flex-direction: column;
  }

  }

  .pricing-plan {
    height: 100%;
  }
  
  /* Your existing CSS here */

/* Media query for mobile screens */
@media (max-width: 768px) {

  .faq-body {
    padding: 30px;
    max-width: 100%;
  }

  .faq-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .faq-item {
    max-width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .faq-question,
  .faq-answer {
    font-size: 12px;
  }


  .pricing-section h2 {
    font-size: 1.7rem;
  }

  .pricing-tagline {
    font-size: 10pt;
  }
}

.discord-invite-button {
  position: fixed; /* Fixed positioning to keep the button in place */
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  width: 60px; /* Width of the button */
  height: 60px; /* Height of the button */
  border-radius: 50%; /* Make it circular */
  background-color: #4f3ee3; /* Discord brand color */
  color: rgb(230, 217, 255); /* Color of the icon */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.527); /* Shadow for 3D effect */
  text-decoration: none; /* Remove text decoration from the link */
  z-index: 11000; /* Ensure it's above other content */
  transition: transform 0.3s, box-shadow 0.3s; /* Transition for hover effect */
}

.discord-invite-button:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
}

/* For mobile screens */
@media (max-width: 768px) {
  .discord-invite-button {
    width: 50px; /* Smaller size for mobile */
    height: 50px;
    bottom: 10px;
    right: 10px;
  }
}
