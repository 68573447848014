.loading-bar {
    width: 100%;
    height: 4px;
    overflow: hidden;
    background-color: #f3f3f3;
    position: relative;
  }
  
  .bar {
    width: 100%;
    height: 4px;
    background-color: #a964da;
    position: absolute;
    animation: loading 2s infinite;
  }
  
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  