@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');


.beta-section {
    z-index: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items:center;
    background-color: #c13fe8;
    background-attachment: fixed;
    color: #dcd0f8;
    padding: 1rem;
  }
  
  .beta-title{
    color: #dcd0f8;
    text-align: left;
    margin-bottom: 3px;
  }

  .video-section {
    width: 100%;
    overflow: hidden;
    border-radius: 30px;
    margin-right: 20px;
  }

  .video-section iframe {
    border-radius: 30px !important; /* The !important flag ensures this style takes precedence */
}

.video-section::before, .video-section::after {
  content: '';
  position: fixed;  /* light purple, adjust opacity as needed */
  border-radius: 20px;
  z-index: -1;
}

.video-section::before {
  background: repeating-linear-gradient(
      45deg,
      #c13fe8,
      #c13fe8 10px,
      black 10px,
      black 20px
  );
  width: 30%;
  height: 20%;
  top: 15%;
  left: -1%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 1), 
              -10px -10px 3px rgba(0, 0, 0, 1); /* Modified shadows for enhanced 3D effect */
}




.video-section::after {
  background-color: #860bac;
  width: 45%; /* adjust as needed */
  height: 15%; /* adjust as needed */
  top: 70%; /* adjust for offset */
  left: 5%; /* adjust for offset */
  box-shadow: 10px 10px 3px rgba(0, 0, 0, 1);
}


  
  .signup-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:left;
    background-color: transparent;
    padding: 1rem;
    border-radius: 30px;
    width: 100%;
    color: #dcd0f8;
  }
  
  
  .beta-text {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }

  .beta-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: 0.2rem;
    color: white;
  }
  
  .name-input,
  .source-input,
  .email-input {
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif;
    background-color: rgba(116, 115, 115, 0.15);
    color: white;
  }
  
  .beta-submit-btn {
    font-family: 'Poppins', sans-serif;

    background-color: rgb(148, 38, 191);
    border: none;
    color: #dcd0f8;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 0.5rem;
    align-self: center;
  }

  .beta-submit-btn:hover {
    background-color: rgb(225, 0, 255); /* A slightly lighter shade of purple */
    transition: background-color 0.3s ease-in-out; /* Smooth transition for color change */
}

.beta-submit-btn:active {
  background-color: rgb(40, 0, 50); /* A slightly darker shade of purple */
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2); /* Gives an inner shadow which gives the button a "pressed" feel */
  transform: scale(0.98); /* Slightly scale down the button to give a "pressed" effect */
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out; /* Faster transition for pressed state */
}

  

  @media screen and (max-width: 768px) {
    .beta-section {
      flex-direction: column-reverse;
      align-items: center;
      padding: 1rem;
      margin: 0 auto;
    }
  
    .video-section {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      margin: 0 auto;
      transform: scale(0.5);
    }
  
    .signup-section {
      width: 90vw;
      max-width: 100%;
      margin-bottom: 0.5rem;
    }
  }