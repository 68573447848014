@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.new-profile-container {
    display: flex;
    background-color: rgb(56, 2, 69); /* New background color */
    height: auto;
    z-index: 0;
  }

  .menu-items div.clicked {
    transform: scale(0.95);  
    background-color: rgb(148, 38, 191);  
  }
  


.new-profile-container::before, .new-profile-container::after {
    content: '';
    position: fixed;
    width: 200px; /* Adjust based on desired blur size. */
    height: 200px; /* Adjust based on desired blur size. */
    border-radius: 50%; /* Make it circular. */
    top: calc(4%); /* Center it vertically, adjust based on blur size. */
    left: calc(10% - 100px); /* Center it horizontally, adjust based on blur size. */
    background: radial-gradient(circle, rgba(141, 14, 141, 0.6), rgba(118, 18, 136, 0.8)); /* Two shades of purple. */
    filter: blur(50px); /* Add blur. */
    z-index: 0; /* Ensure blurs appear behind any other content. */
  }
  
  .new-profile-container::after {
    top: calc(40%);
    left: calc(80%);
    background: radial-gradient(circle, rgba(170, 3, 170, 0.6), rgba(86, 3, 145, 0.8));
     /* Two other shades of purple. */
    }

  .profile-menu {
    width: 15%;
    background-color: transparent;
    color: #e3dbf5;
    position: fixed;
    font-weight: bold;
    font-size: 14pt;
    top: 10vh;
    z-index: 1;
  }
  
  .profile-menu .active {
    background-color: rgb(148, 38, 191); /* Active title background */
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .profile-content {
    width: 85%;
    color: #e3dbf5; /* New text color */
    padding: 10px;
    z-index: 1;
  }
  
  .content-section h2 {
    text-align: left;
    color: #e3dbf5; /* New text color */
  }
  
  .logout-button {
    background-color: rgb(148, 38, 191); /* Logout button background */
    color: #e3dbf5; /* Text color */
    border-radius: 5px;
  }

  .profile-menu {
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    height: 100vh;
    /* ...existing styles */
  }
  
.menu-items div {
    padding: 8px 12px;  /* Adjust these values as needed */
    box-sizing: border-box;
  }
  
  /* ...existing styles */
  
  
  .logout-button {
    margin-top: auto; /* This pushes the button to the bottom */
    align-self: center;
    margin-bottom: 6rem;
    font-size: 12pt;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: auto;
    /* ...existing styles */
    width: 95%;
  }

  .info-box {
    width: 90%;
    padding: 20px;
    padding: 20px;
    color: #e3dbf5;
    font-weight: bold;
    font-size: 12pt;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .usage-bar-container {
    height: 20px;
    width: 100%;
    background-color: #e3dbf5;
    border-radius: 50px;
    margin-bottom: 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);

  }
  
  .usage-bar {
    height: 100%;
    background-color: #1f0034;
    border-radius: inherit;
    transition: width .2s ease-in;
  }
  
  .connected-accounts-container {
    display:flex ;
    flex-direction: column;
    align-items: left;
    padding: 10px;
    background-color: #320b4100;
    color: rgb(221, 221, 221);
  }


  .tiktok-connected-info {
    background: #f2e7ff; /* Light purple background */
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .tiktok-connected-info img {
    width: 50px;
    height: 50px;
    border-radius: 25px; /* Circular avatar */
    margin-right: 15px;
  }
  
  .tiktok-connected-info p {
    margin: 5px;
    color: #5d3a7a; /* Dark purple text */
  }
  
  .nav-link-tiktok {
    background-color: #1f0034; /* Dark purple button */
    color: #e3dbf5; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 30%;
    margin-bottom: 10px;
    font-size: 12pt;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .nav-link-tiktok:hover {
    background-color: rgb(148, 38, 191); /* Lighter purple on hover */
  }

  .tiktok-title {
    color: #f2e7ff; /* Dark purple text */
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .profile-content {
    margin-left: 15%;
     /* ... other existing styles */
  }
  
  .new-profile-container {
    display: flex;
  }
  
  .profile-menu {
    height: 100vh;
    /* ... other existing styles */
  }

  .outer-affilaites-container {
    width: 100%;
    padding-left: 3rem;

  }

.affiliates-container {
    color: #e3dbf5;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;
    padding: 30px;
    padding-left: 10px;
    text-align: left;
    padding-bottom: 30px;
    
}

.affiliate-info-button{
  background-color: #1f0034; /* Dark purple button */
  color: #e3dbf5; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 30%;
  margin-bottom: 10px;
  font-size: 12pt;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.affiliate-info-button:hover {
  background-color: rgb(148, 38, 191); /* Lighter purple on hover */
}

.affiliates-info {
    height: 0vh;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    /* Additional styles for the info dropdown */
  }
  
  .affiliates-info.show {
    height: auto;
    max-height: 1000px; /* Adjust as needed */
    
  }

.affiliates-container h1, .affiliates-container h2 {
    color: #e3dbf5;
    margin-bottom: 20px;
}

.affiliates-container p {
    margin-bottom: 20px;
    color: #e3dbf5;
    font-weight: bold;
}

.affiliates-button {
    background-image: linear-gradient(135deg, #6b198c 0%, #673e75 100%);
    color: #e3dbf5; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 30%;
    margin-bottom: 10px;
    font-size: 12pt;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.affiliates-button:hover {
    background-image: linear-gradient(135deg, #a11fd4 0%, #b06dc8 100%);

}

.affiliates-action-container {
    display: grid;
     /* Adjust as needed */
    /* Add any other desired styles */
  }


/*Subscription CSS
/* This will be for the outer container */
.outer-subscription-container {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.sidebar-container {
  width: 20%;
}

.subscription-container {
  width: 100%;
  height: 95%;
  background-color: #1f0034;
  padding: 0.4rem;
  align-items: center;
  border-radius: 10px;
  
}

.subscription-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.subscription-type {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.stripe-c {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.update-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  border-color: transparent;
  cursor: pointer;
  background-color: #a11fd4;
}


/* Media query for the Account Page */
@media (max-width: 768px) {
  .new-profile-container {
    flex-direction: column;
  }
  .profile-menu {
    display: none; /* Hide the profile menu */
  }
  .profile-content {
    width: 100%;
    margin-left: 0;
  }
  .info-box, .connected-accounts-container {
    width: 95%;
    margin: 0 auto;
  }
  .logout-button, .affiliate-info-button, .nav-link-tiktok, .affiliates-button {
    width: 80%;
    margin: 0 auto 10px auto;
  }
  .subscription-container {
    padding: 0.2rem;
  }
  /* Adjust text sizes for better readability */
  h1, h2, p, .subscription-info, .subscription-type {
    font-size: 16px;
  }

  .usage-bar-container {
    width: 30%;
  }
  /* ... Any other mobile-specific styles you might need ... */
}
