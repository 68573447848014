.feedback-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    background-color: rgba(80, 10, 90, 0.7); /* dimmed background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Make sure it's on top */
  }
  
  .feedback-content {
    max-width: 800px;
    background-color: rgb(241, 231, 253);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: left;
    gap: 20px;
    height: 63%;
  }
  
  .feedback-intro__title, .feedback-intro__subtitle, .feedback-intro__smalltext {
    margin: 0;
    padding: 0.3rem;
    text-align: left !important;
    font-weight: bold;
  }
  
  .feedback-intro__title {
    font-size: 2.5rem;
    color: rgb(27, 2, 69);
  }
  
  .feedback-intro__subtitle {
    font-size: 1.2rem;
    color: rgb(224, 102, 210);;
  }
  
  .feedback-intro__smalltext {
    font-size: 1.2rem;
    color: rgb(224, 102, 210);;
  }
  
  .slide {
    display: none;
    transition: all 0.3s ease;
    width: 100%;
  }
  
  .slide.active {
    display: block;
  }
  
  .options button {
    background-color: transparent;
    color: rgb(27, 2, 69);
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .options button:hover {
    background-color: rgb(80, 10, 90);
  }
  


.feedback-content {
    display: flex;
  }
  
  .feedback-intro {
    flex: 1;
    padding: 20px;
    width: 50%;
    /* Add more styles for text alignment, etc. */
  }
  
  .feedback-questions {
    display: flex;
    transition: transform 0.3s ease-in-out;
    /* Add overflow hidden to parent if necessary */
  }
  
  .feedback-question__title {
    flex: 1;
    color: rgb(19,2,48);
    /* Add more styles */
  }

  /* ...existing styles... */
  .feedback-question {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.3s ease-in-out;
  }
  
  .feedback-question__slide {
    flex-basis: 100%;
    flex-shrink: 0;
    display: none; /* Hide by default */
  }
  
  .feedback-question__slide.active {
    display: block; /* Show only if it's active */
  }
  
  
  .feedback-question__title {
    /* Prevent style conflicts */
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    text-align: center;
  }
  
  .feedback-options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .feedback-option {
    background-color: transparent;
    border: 3px solid ;
    border-radius: 5px;
    color: rgb(224, 102, 210);
    font-weight: bold;
    font-size: 0.9rem;
    padding: 10px;
    margin: 5px;
    width: 100%;
    max-width: 200px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .feedback-option:hover {
    background-color: rgba(134, 11, 172, 0.1);
  }
  
  .feedback-option.selected {
    background-color: rgb(224, 102, 210);
    border-color: rgb(224, 102, 210);
    color: rgb(245, 227, 250);
    animation: clickEffect 0.3s ease;
  }

  .feedback-next-button {
    background-color: rgb(128, 1, 115);
    border-color: rgb(128, 1, 115);
    border-radius: 5px;
    color: rgb(245, 213, 255) ;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .feedback-next-button:hover, .feedback-upgrade-button:hover {
    border-color: rgb(18, 0, 47);
    background-color: rgb(18, 0, 47);
    color: rgb(245, 219, 253);
  }
  
  .feedback-next-button:active, .feedback-upgrade-button:active {
    animation: clickEffect 0.3s ease;
  }
  
  .feedback-next-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem; /* Or however much space you want above the button */
  }
  
  .feedback-content {
    display: flex;
    width: 100%;
  }
  
  .feedback-intro,
  .feedback-questions {
    flex: 1;
    width: 50%; /* Explicitly set to 50% */
     /* Scroll if content is too tall */
    position: relative; /* For absolute/fixed positioned children */
  }
  
  /* Your other styles for these classes remain unchanged */
  .feedback-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;  /* make this container relative */
  }
  
  .feedback-next-button, .feedback-upgrade-button {
   
    bottom: 0;  /* align it to the bottom of the parent container */
    width: 50%;
    
    background-color: rgb(224, 102, 210);
    border-color: rgb(224, 102, 210);
    color: rgb(245, 219, 253);
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  

  
  @keyframes clickEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  .feedback-code-section {
    display: flex;
    align-items: center;
    background-color: #26024f;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .feedback-code-section code {
    margin: 0 5px;
    color: white;
  }
  
  .feedback-code-section button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .feedback-next-button-wrapper {
    display: flex;
    justify-content: center;
    gap: 16px;  /* Spacing between buttons */
  }
  
  .feedback-upgrade-button {
    flex-grow: 1;  /* Takes up more room */
  }
  
  .feedback-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;  /* Center the buttons */
    gap: 16px;  /* Gap between the buttons */
  }
  
 
  /* ...existing styles... */

.feedback-button-container {
    position: relative;  /* New container for button positioning */
    height: 50px;  /* Or whatever height you want */
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  
  .feedback-next-button,
  .feedback-upgrade-button {

    bottom: 0;
    background-color: rgb(224, 102, 210);
    border-color: transparent;
    border-radius: 5px;
    color: rgb(252, 243, 255);
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0px 0px;
    font-weight: bold;
  }
  
  .feedback-upgrade-button {
    width: 60%;  /* Change according to your needs */
  }
  
  .feedback-next-button {
    width: 40%;  /* Change according to your needs */
  }
  
  /* ...the rest of your styles... */
  
  .bold-header, .bold-paragraph {
    font-weight: bold;
    color: rgb(18, 0, 47);
  }
  
  .feedback-code-section {
    display: flex;
    height: 2.5rem;
    align-items: center;
    background-color: rgb(224, 102, 210);
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .feedback-code {
    margin: 0 5px;
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2px 5px;
    border-radius: 3px;
  }
  
  .feedback-copy-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    text-align: right;
    margin-left: 50px;
    padding: 2px 6px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: bold;
  }
  
  .feedback-copy-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
/* Mobile styles */
@media (max-width: 768px) {
  .feedback-modal {
    height: 100%; /* Take full height on mobile */
  }

  .feedback-content {
    flex-direction: column; /* Stack the content vertically on mobile */
    align-items: center; /* Center the content on mobile */
    height: auto; /* Let the content define the height */
    overflow-y: auto; /* Enable scrolling if the content is too long */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .feedback-intro,
  .feedback-questions {
    width: 100%; /* Full width on mobile */
    margin-bottom: 20px; /* Add some space between sections */
  }

  .feedback-intro__title {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .feedback-intro__subtitle,
  .feedback-intro__smalltext {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .feedback-option {
    padding: 8px; /* Smaller padding for options */
    margin: 3px; /* Less margin for options */
    font-size: 0.8rem; /* Smaller font size for options */
  }

  .feedback-next-button,
  .feedback-upgrade-button {
    padding: 4px; /* Smaller padding for buttons */
    font-size: 0.9rem; /* Smaller font size for buttons */
    width: auto; /* Auto width for buttons */
    flex-grow: 0; /* Reset flex-grow for buttons */
  }

  .feedback-button-container {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }

  .feedback-code-section {
    flex-direction: row; /* Stack code section elements on smaller screens */
    align-items: center; /* Center items in the code section */
    text-align: center; /* Center the text for small screens */
  }

  .feedback-copy-button {
    margin-top: 0px; /* Add margin-top for the copy button when stacked */
    width: auto; /* Auto width for the copy button */
  }
}

  
  