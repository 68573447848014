/* BlogPost.css */
.blog-post-container {
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px;
    background-color: #1f0034; /* dark background */
    color: #ffffff; /* light text */
  }

  .blog-page-container {
    background-color: #1f0034; /* Dark background */
    /* ...other styles... */
  }
  
  .blog-post-error {
    color: #ff6b6b; /* Error text color, adjust as needed */
  }
  
  .blog-post-loading {
    color: #d8caf9; /* Loading text color, similar to other text */
  }
  