



/* Styles for the <div> with class "faq" */
.faq {
  /* Makes the <div> a flex container, which helps align its children */
  display: flex;

  /* Arranges the children of the <div> in a column */
  flex-direction: column;

  /* aligns the children of the <div> to the left */
  align-items: flex-start;

  /* Centers the children of the <div> horizontally */
  justify-content: center;
  width: 100%;
}

/* Styles for the <button> with class "faq-question" */
.faq-question {
  /* Changes the cursor to a pointer when hovering over the button */
  cursor: pointer;

  /* Sets the text color inside the button to a light color */
  

  /* Adds space below the button */
  margin-bottom: 5px;

  /* Adds padding around the text inside the button */
  padding: 15px;

  /* Sets the width of the button to 80% of their parent element */
  width: 100%;

  /* Sets the background color of the button to a dark shade */

  /* Removes the default button border */
  border: none;

  /* Rounds the corners of the button */
  border-radius: 5px;

  /* Adds a small shadow to the button */

  /* Adds a transition effect to all properties of the button */
  transition: all 0.3s ease;
}

/* Styles for the <button> with class "faq-question" when it's being hovered over */


/* Styles for the <p> with class "faq-answer" */
.faq-answer {
  /* Adds space around the text inside the paragraph */
  padding: 20px;

  /* Sets the width of the paragraph to 80% of its parent element */
  width: 80%;

  /* Sets the background color of the paragraph to a dark shade */

  /* Rounds the corners of the paragraph */
  border-radius: 5px;

  /* Adds a small shadow to the paragraph */
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);

  /* Sets the text color inside the paragraph to a light color */
  

  /* Adds space below the paragraph */
  margin-bottom: 20px;
}
