.tiktok {
    display: flex;
    align-items: center;
    background-color: #f4f2ff; /* Light purple background */
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .tiktok img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 10px;
  }
  
  .tiktok p {
    font-weight: bold;
    color: #5d3a7a; /* Dark purple text */
    margin: 0;
  }
  
  .tiktok h4 {
    font-weight: normal;
    color: #5d3a7a; /* Dark purple text */
    margin: 0;
  }
  
  label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .option {
    display: flex;
    justify-content: space-between;
  }
  
  .option div {
    display: flex;
    align-items: center;
  }
  

  .tiktokbtn {
    background-color: #5d3a7a; /* Dark purple background */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .tiktokbtn:hover {
    background-color: #7a5dc3; /* Slightly lighter purple on hover */
  }
  
  select {
    background-color: #f4f2ff; /* Light purple background */
    color: #5d3a7a; /* Dark purple text */
    padding: 8px;
    border: 1px solid #5d3a7a; /* Dark purple border */
    border-radius: 5px;
    cursor: pointer;
  }
  
  option {
    background-color: #f4f2ff; /* Light purple background for options */
    color: #5d3a7a; /* Dark purple text */
  }
  
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    background-color: #f4f2ff; /* Light purple background */
    border: 2px solid #5d3a7a; /* Dark purple border */
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  input[type="checkbox"]:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    background-color: #5d3a7a; /* Dark purple check */
  }
  
  input[type="checkbox"]:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .TiktokUpload-container {
    background-color: #faf7ff; /* Very light purple background */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(93, 58, 122, 0.1); /* Subtle shadow with purple hue */
    box-sizing: border-box;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
     /* This assumes you want the spinner to be vertically centered on the entire viewport. Adjust as needed. */
  }
  
  .spinner-tok {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #860bac; /* Your theme color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  
  