html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
}


.footer {
  color: #e3dbf5;
  padding: 20px 0;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  margin: 0;
  
  position: relative;
  margin-top: auto;
  z-index: 10000;
}

.footer {
  background: #1f0034;
  /* ...other styles... */
}


.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 100000;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.footer-link {
  color: #e3dbf5;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
}
.footer-link,
.footer-link:visited {
  color: #e3dbf5;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
}

.footer-link:hover {
  color: #8853E6;
}

.footer-link:focus {
  outline: 2px solid #8853E6;
}

.footer-social {
  display: flex;
  justify-content: center; /* Align center instead of space-between */
  align-items: center;
  width: 100%;
}

.social-icons {
  display: flex;
  align-items: center;
  margin: 0 20px; /* Add some horizontal margin for spacing */
}

/* ...other styles... */


.social-link {
  color: #e3dbf5;
  margin: 0 10px;
  font-size: 1.5rem; /* You can adjust the size here */
}

.social-link:hover {
  color: #8853E6; /* Match the hover color of other links */
}




@media (max-width: 768px) {
  .footer-container {
    padding: 15px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-link {
    margin: 5px 0; /* Adjust the margin for stacked links */
  }
}
