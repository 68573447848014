.signup-container {
  background-color: #F4F4F4;
  height: 100vh;
}

.signup-form-container {
  background-color: #FFFFFF;
  margin: 0 auto;
  max-width: 450px;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.signup-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.signup-input {
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 16px;
  font-size: 14px;
}

.signup-button {
  background-color: #a964da;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
  transition: background-color 0.3s ease-in-out;
}

.signup-button:hover {
  background-color: #0066CC;
  cursor: pointer;
}

.separator {
  display: flex;
  align-items: center;
  margin: 40px 0;
}

.separator hr {
  flex-grow: 1;
  border: none;
  height: 1px;
  background-color: #000;
}

.separator span {
  margin: 0 10px;
}
.google-signin-container {
  display: flex;
  justify-content: center;
  max-height: 100px;
}