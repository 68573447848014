/* Learn.css */

.title-section {
  background-color: #1f0034;; /* dark background */
  color: rgb(224, 102, 210); /* light text */
  padding: 30px 0; /* vertical padding */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes glideIn {
  from {
    transform: translateY(-50px); /* starting position */
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.title-section h1 {
  animation: glideIn 1s forwards; /* Add the glide-in animation */
  margin: 0;
  padding: 0px 0; /* spacing */
  font-size: 5em; /* larger font size */
  font-weight: bolder;
}

.title-section p {
  color: #ffffff;
  margin: 0;
  padding: 5px 0; /* spacing */
  font-size: 1.2em; /* larger font size */
}


.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: #1f0034; /* dark background for the blog grid */
}

.blog-post-preview {
  transition: transform 0.3s ease; /* This line enables smooth scaling */
  display: flex;
  flex-direction: column;
  background-color: #e066d2; /* lighter background for individual posts */
  color: #ffffff; /* text color */
  border-radius: 8px;
  overflow: hidden; /* Hide overflow text or images */
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for a better "card" like styling */
  height: 400px; /* Fixed height; adjust as needed */
}

.blog-post-preview:hover {
  transform: scale(1.05); /* This line scales the item when hovered */
}


.blog-post-image {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover;
}

.blog-post-content {
  margin: 16px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This helps push the author to the bottom */
  flex-grow: 1; /* Allows this container to take up any remaining space */
}

.blog-post-title, .blog-post-description {
  margin-bottom: 3px; /* Adjust as needed */
}

/* New addition to handle text overflow */
.blog-post-description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines you want displayed. Adjust as needed. */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.author-info {
  display: flex;
  align-items: center;
  margin-top: auto;
  
 
}

.author-image {
  width: 50px; /* adjust as needed */
  height: 50px; /* adjust as needed */
  border-radius: 50%; /* creates the circular image */
  margin-right: 10px; /* space between image and author details */
  object-fit: cover; /* ensures the image covers the entire area */
}

.author-details {
  display: flex;
  flex-direction: column; /* stack the name and position */
}

.author-name {
  margin: 0;
  font-weight: bold;
  color: #ffffff; /* or another color */
}

.author-position {
  margin: 0;
  font-size: 0.9em; /* smaller than the name */
  color: #ffffff; /* or another color */
}

.blog-post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-post-date {
  font-weight: bold; /* Make the date bold */
  font-size: 0.8rem;
  color: #e4e4e4; /* white color for the date */
  
  margin: 0 0 0 0px;
}

.blog-post-title {
  margin-top: 0;
}