.affiliates-background{
    background-color: rgba(56, 2, 69);
}

.affiliates-public {
    max-width: 1480px;
    margin: 0 auto;
    padding: 20px;
    color: #333; /* Adjust text color as needed */
    background-color: #f5f5f5; /* Background color */
  }

  .affiliates-public {
    background: rgba(56, 2, 69);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    font-family: 'Poppins', sans-serif;
    position: relative;
    overflow: hidden;
    z-index: 0;
  }
  
  .affiliates-public-info {
    line-height: 1.6;
  }
  
  .affiliates-public h1 {
    color: #f3e4fa;
  }
  
  .affiliates-public h2,
  .affiliates-public p {
    color: #e3dbf5; /* Using your theme color */
  }

  .affiliates-public h1 {
    font-size: 3rem;
  }

  .affiliate-hook {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    margin: 10px;
  }
  
  
  
  .affiliates-public-action-container {
    text-align: center; /* Centers the button */
    margin: 1rem 0; /* Extra margin to space it from other elements */
    width: 90%;
  }
  
  .affiliates-public-button {
    font-size: 1.5rem; /* Larger font size */
    padding: 20px 30px; /* Larger padding */
    width: 60%;
    background: linear-gradient(135deg, #860bac, rgb(161, 20, 179)); /* Gradient background */
    border: none; /* Removes border */
    color: #ebe6ff; /* Text color */
    border-radius: 30px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .affiliates-public-button:hover {
    transform: scale(1.05); /* Slightly enlarges the button on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increases shadow depth on hover */
  }
  

.affiliates-public::before, .affiliates-public::after {
    content: '';
    position: fixed;
    width: 200px; /* Adjust based on desired blur size. */
    height: 200px; /* Adjust based on desired blur size. */
    border-radius: 50%; /* Make it circular. */
    top: calc(4%); /* Center it vertically, adjust based on blur size. */
    left: calc(10% - 100px); /* Center it horizontally, adjust based on blur size. */
    background: radial-gradient(circle, rgba(141, 14, 141, 0.6), rgba(118, 18, 136, 0.8)); /* Two shades of purple. */
    filter: blur(50px); /* Add blur. */
    z-index: -1; /* Ensure blurs appear behind any other content. */
  }
  
  .affiliates-public::after {
    top: calc(40%);
    left: calc(80%);
    background: radial-gradient(circle, rgba(170, 3, 170, 0.6), rgba(86, 3, 145, 0.8));
     /* Two other shades of purple. */
  }


  
  /* Link styles if needed */
  a {
    color: #860bac;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  .affiliate-icon {
    width: 10rem;
    height: 10rem;
    position: relative;
    
    border-radius: 10%;
    display: inline-block;
    color: #e3dbf5;
  }
  
  .affiliate-images {
    display: flex;
    flex-direction: row;
    padding: 0rem 5rem;
    justify-content: space-between;
    margin: 10px;
  }

  .benefits {
    padding: 2rem;
    text-align: center;
  }
  
  .benefits h2 {
    font-size: 3rem;
    color: #f3e4fa;
    margin-bottom: 1rem;
  }
  
  .benefits-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem;
    flex-wrap: wrap; /* To make it responsive */
  }
  
  .benefit-item {
    flex: 1;
    background-color: #13012c;
    background: linear-gradient(135deg, #13012c, #170137);
    border-radius: 30px;
    padding: 1.2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
  
  .benefit-item h3 {
    font-size: 1.5rem;
    color: #ebe6ff;
    margin-bottom: 1rem;
  }
  
  .benefit-item p {
    color: #d8caf9;
    font-size: 1rem;
  }
  
  .benefit-icon {
    font-size: 50px;
    margin-bottom: 1rem;
    color: #ebe6ff;
  }
  
  .how-it-works, .who-can-join {
    text-align: center; /* Centering text */
    padding: 2rem;
    margin: 1rem 0;
  }
  
  .how-it-works h2, .who-can-join h2 {
    font-size: 3.5rem; /* Larger heading size */
    color: #e3dbf5; /* Color theme */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow */
    margin-bottom: 1rem;
  }
  
  .how-it-works p, .who-can-join p {
    font-size: 2rem; /* Larger paragraph text */
    font-weight: bold;
    line-height: 1.5;
    color: #d8caf9; /* Color theme */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); /* Text shadow */
  }
  
  /* You may want to apply a specific style to the button or links within these sections */
  
  
  /* If you want to add any specific styling for buttons or other elements within these sections, add them here */
  
  .how-it-works {
    text-align: center; /* Centering text */
    padding: 2rem;
  }
  
  .how-it-works h2 {
    font-size: 3.5rem; /* Larger heading size */
    color: #e3dbf5; /* Color theme */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow */
    margin-bottom: 1rem;
  }

  .how-it-works h1 {
    font-size: 2.5rem; /* Larger heading size */
    color: #e3dbf5; /* Color theme */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow */
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
  
  .works-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  
  .works-item {
    flex: 1;
    background-color: #13012c;
    background: linear-gradient(135deg, #13012c, #170137);
    border-radius: 30px;
    padding: 1.2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
  
  .works-item h3 {
    font-size: 1.5rem;
    color: #ebe6ff;
    margin-bottom: 1rem;
  }
  
  .works-item p {
    color: #d8caf9;
    font-size: 1rem;
  }
  
  .works-icon {
    font-size: 24px; /* adjust size as needed */
    margin-bottom: 1rem; /* space between the icon and the description */
    color: #ebe6ff; /* adjust color as needed */
  }
  
  .works-icon {
    transition: transform 0.3s ease;
  }
  
  .works-item:hover .works-icon {
    transform: rotate(15deg); /* Gently rotates the icon */
  }
  
  .aggressive {
    color: #af42e5;
  }
  
  /* Mobile Styles (up to 768px) */
@media (max-width: 768px) {

  .affiliates-public h1 {
    font-size: 1.8rem;
  }


  .affiliate-hook {
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .affiliates-public-button {
    font-size: 1rem;
    padding: 10px 15px;
    width: 70%; 
  }

  .affiliate-icon {
    width: 6rem;
    height: 6rem; 
  }

  .benefits,
  .how-it-works,
  .who-can-join {
    width: 80%; /* Limit the width */
    margin: 0 auto; /* Center the container */
  }

  .benefits-row,
  .works-row {
    flex-direction: column;
    align-items: center; /* Center the items */
  }

  .benefit-item,
  .works-item {
    margin: 8px 0;
    width: 80%; /* Reduce width */
  }

  .how-it-works h2, 
  .who-can-join h2 {
    font-size: 2rem;
  }

  .how-it-works h1 {
    font-size: 1.8rem;
  }

  .works-icon {
    font-size: 18px;
  }
  
  /* If you have other specific adjustments for mobile, add them here */
}
