.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0; /* Start fully transparent */
    pointer-events: none; /* Prevent interactions when hidden */
    transform: translateY(100%); /* Start translated down */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Animate opacity and transform */
  }
  
  .modal.show {
    opacity: 1; /* Fully opaque when showing */
    pointer-events: auto; /* Allow interactions when showing */
    transform: translateY(0); /* Translate to original position */
  }
  
  .modal-content {
    position: relative;
    background-color: transparent;
    width: 80%;
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .upload-close-button {
    position: absolute;
    top: 45px;
    right: 142px;
    font-size: 30px;
    cursor: pointer;
    color: #860bac;
    border-radius: 50%;
    padding: 5px;
    line-height: 30px;
    text-align: center;
  }
  
  
 
  