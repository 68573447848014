.nav-link-tiktok:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
  .con-container {
    display:flex ;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #320b4100;
    color: rgb(221, 221, 221);
  }

  .tiktok-connected-info {
    background: #f2e7ff; /* Light purple background */
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .tiktok-connected-info img {
    width: 50px;
    height: 50px;
    border-radius: 25px; /* Circular avatar */
    margin-right: 15px;
  }
  
  .tiktok-connected-info p {
    margin: 5px;
    color: #5d3a7a; /* Dark purple text */
  }
  
  .nav-link-tiktok {
    background-color: #5d3a7a; /* Dark purple button */
    color: #fff; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .nav-link-tiktok:hover {
    background-color: #7a5da3; /* Lighter purple on hover */
  }

  .tiktok-title {
    color: #f2e7ff; /* Dark purple text */
    margin-bottom: 15px;
    font-weight: bold;
  }
  