/* ContactUs.css */
.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  .contact-us h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
  }
  
  .contact-form label {
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 0.5rem;
  }
  
  .contact-form textarea {
    min-height: 200px;
    resize: vertical;
  }
  
  .submit-button {
    background-color: #8853E6;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #59487c;
  }
  