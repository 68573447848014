/* Add these classes */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: linear-gradient(135deg, #320b41 0%, #4d185e 100%);
}


.sidebar-toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  .profile-sidebar {
    transform: translateX(0);
  }

  .profile-sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .profile-sidebar.open {
    transform: translateX(0);
  }

  .profile-container {
    flex-direction: column;
  }

  .prof-container {
    height: auto;
    padding-bottom: 20px;
  }

  .sidebar-toggle {
    display: block;
  }
}

