
.profile-sidebar {
    display: flex;
    flex-direction: column;
    position:absolute;
    left: 0;
    top: 10%;
    background-color: #320b41;
    padding: 20px;
  }

  .profile-sidebar {
    width: 200px;
    height: calc(100vh - 99px); /* Adjust based on the height of your header and footer */
    padding: 20px;
    overflow-y: auto;
    border-right: 3px solid rgb(181, 181, 181);
  }
  
  .profile-sidebar .sidebar-link {
    font-weight: bold;
    padding: 0.3rem;
    margin: 10px 0;
    text-decoration: none;
    color: rgb(181, 181, 181);
  }
  
  .profile-sidebar .sidebar-logout {
    background-color: rgb(137, 74, 141);
    color: white;
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: auto;
  }
  
  .close-button {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .close-button{
      display: block;
    }
  }