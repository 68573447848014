.chat-container {
    width: 100%;
    height: 86%;
    margin-top: 2rem;
    border: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1rem;
    position: relative; /* Set to relative for potential pseudo-elements or absolute children */
}

.chat-container {
    /* ... other styles ... */
    transition: opacity 0.3s ease-out;
}

.chat-closed {
    opacity: 0;
    pointer-events: none; /* Ensures the invisible chatbot doesn't interfere with any clicks */
}


.chat-messages {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    transition: all 0.3s ease-in-out; /* Smooth transition for chat scroll */
    
}

.chat-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chat-input {
    flex-grow: 1;
    padding: 8px;
    background-color: white;
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 4px;
    transition: border 0.3s, box-shadow 0.3s; /* Transition effect for focus */
}

.chat-input:focus {
    border-color: rgb(90, 14, 128); /* Match the button color */
    box-shadow: 0 0 5px rgba(90, 14, 128, 0.5); /* Soft glow effect */
    outline: none; /* Remove browser default outline */
}

.chat-send-button {
    padding: 8px 12px;
    border: none;
    background-color: rgb(90, 14, 128);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s; /* Added transform transition */
}

.chat-send-button:hover {
    background-color: rgb(70, 10, 100); /* Darker shade on hover */
    transform: scale(1.05); /* Slight enlargement on hover */
}

.chat-message {
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 8px;
    opacity: 0; /* Initially set messages to invisible */
    transform: translateY(10px); /* Initially position messages a bit lower */
    animation: popIn 0.5s forwards; /* Apply pop-in animation */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 60%;
    align-self: flex-start;
}

@keyframes popIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}




.user {
    background-image: linear-gradient(to bottom right, #7a3aa8, #a964da);
    align-self: flex-end;
    color: white;
    padding: 15px;
    margin-left: auto; /* pushes the message to the right */
 /* slight overlap with next message */
}

.assistant {
    background-image: linear-gradient(315deg, #a964da 0%, #4b3f72 74%);
    color: white;
    padding: 15px;
    margin-right: auto;
}

.chat-messages::-webkit-scrollbar {
    width: 8px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: rgb(90, 14, 128); 
    border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.chat-send-button:active {
    transform: scale(1); /* Reset the button size to its original scale when pressed */
    background-color: rgb(70, 10, 100); /* Darker shade on active press */
}

.typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 10%;
    gap: 10px;
    padding: 10px;
    height: 20px;
    margin: 10px 5px;
    background-image: linear-gradient(315deg, #a964da 0%, #4b3f72 74%);
    color: white;
    border-radius: 4px;
    overflow: hidden; /* This will ensure the dots stay within the bounds of the container */
}

/* ...rest of the CSS remains unchanged... */


.typing-indicator > span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    animation: bounce 1.2s infinite;
}

.typing-indicator > span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator > span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}



