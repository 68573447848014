/* StripeContainer.css */
.stripe-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

  
  .stripe-container stripe-pricing-table {
    width: 100%;
  }
  