@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.tooltipContainer {
  position: relative;
  display: inline-block;
}

.customTooltip {
  position: absolute;
  bottom: 100%;
  left: 1%;
  transform: translateX(-1%);
  background-color: #1e0139; /* Adjust this to your theme color */
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none; /* Ensures the tooltip doesn't interfere with clicks */
}

.tooltipContainer:hover .customTooltip {
  opacity: 1;
}



.clip-settings {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.clip-settings::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
  background-size: 200px 200px;
  z-index: -1;
}


h1 {
  text-align: center;
  z-index: 1;
}

.color-selector {
  display: flex;
  flex-wrap: wrap;
}

.color-option {
  display: flex;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
}

.color-option.selected {
  border-color: #000;
}

.color-box {
  width: 45px;
  height: 30px;
  margin-right: 2px;
}

.color-option.selected {
  border-color: #000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1.5);
}


.clip-settings-content {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content:space-between;
  margin-top: 10px;
}

/* Style for the parent container */
.video-container {
  position: relative; /* Absolute positioning within tab-container */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}

/* Common styles for both video and image */
.video-container video, .video-container img {
  max-width: 100%;
  max-height: 65vh; /* or whatever height you want to set as max */
  object-fit: contain; /* Keeps the aspect ratio */
  border-radius: 0 5px 5px 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Individual styles for the video */
.video-container video {
  width: 100%;
}

/* Individual styles for the image */
.video-container img {
  object-fit: contain; /* Keep aspect ratio */
}

.video-preview {
  overflow: hidden;; /* Keep aspect ratio */
}

.loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #882fb8; /* Spinner color */
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}




.form-container {
  box-sizing: border-box;
  position: relative;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 15px;
  width: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-container {
  max-height: 80vh; /* 80% of viewport height, but you can adjust this */
  min-height: 80vh;
  overflow-y: visible;
}

.form-container {
  display: flex;
  flex-direction: column;
}


.accordion-container {
  margin-top: 5px;
  max-height: 45vh; /* You can adjust this value to your preference */
  overflow-y: auto; /* This makes it scrollable vertically */
  padding-right: 5px; /* To prevent inner content from touching the scrollbar */
}

.accordion-container {
  flex-grow: 1;
  overflow-y: auto;
}

.accordion-container {
  scroll-behavior: smooth;
  /* ... other styles ... */
}



label {
  display: block;
  margin-bottom: 10px;
}

select {
  font-size: 16px;
  padding: 4px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 4px;
}

.get-started-btn,
.continue-btn,
.generate-clips-btn {
  font-family: 'Poppins', sans-serif;

  background-color: #a964da;
  color: #fff;
  padding: 12px 10px;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
  width: 100%;
  border: none;
  box-shadow: 0 4px
  6px rgba(0, 0, 0, 0.1);
  background:#7a3aa8;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 5px;
  }
  
  .get-started-btn:hover,
  .continue-btn:hover,
  .generate-clips-btn:hover {
  background-color: #7a3aa8;
  background-image: linear-gradient(to bottom right, #7a3aa8, #a964da);
  }

  .chat-btn {
    font-family: 'Poppins', sans-serif;
    background-color: #a964da;
    background-image: linear-gradient(315deg, #a964da 0%, #4b3f72 74%); /* Gradient for added depth */
    color: #fff;
    padding: 12px 10px;
    font-size: 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 3px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    position: relative; /* For pseudo-element */
    overflow: hidden; /* To contain pseudo-element */
    bottom: 0;
}

.bottom-btns{
  position: absolute;
  bottom: 0;
  width: 96%;
}

/* Adding a pseudo-element for a shiny effect on hover */
.chat-btn::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.1);
    transform: rotate(-30deg) translate(-75%, -75%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.chat-btn:hover {
    background-image: linear-gradient(315deg, #b375da 0%, #534882 74%);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px); /* Slight upward shift on hover for a "lifting" effect */
}

.chat-btn:hover::before {
    opacity: 1; /* Show shiny effect on hover */
}

.close-btn {
  position: absolute;
  top: 1px;
  right: 5px;
  background-color: #7a3aa8;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.close-btn:hover {
  background-color: #a964da;
}




  .button-group {
    display: flex;
    justify-content: space-between;
  }

  .back-btn {
    font-family: 'Poppins', sans-serif;
  
    background-color: #d7aef4;
    color: #fff;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
    margin-right: 2%;
    width: 20%;
    border: none;
    box-shadow: 0 4px
    6px rgba(0, 0, 0, 0.1);
    background:#5c0779;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    margin-top: 50px;
    }

  .back-btn:hover {
      background-color: #7a3aa8;
      background-image: linear-gradient(to bottom right, #7a23b9, #d1a7ef);
      }
  
  
  .view-generated-clips-btn,
  .regenerate-clips-btn {
  font-family: 'Poppins', sans-serif;
  display: block;
  background-color: #a964da;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 20px auto;
  text-align: center;
  width: 80%;
  text-decoration: none;
  font-weight: bold;
  background: linear-gradient(to top, #a964da, #7a3aa8);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  }
  
  .regenerate-clips-btn {
  background-color: #a964da;
  }
  
  .view-generated-clips-btn:hover,
  .regenerate-clips-btn:hover {
  background-color: #7a3aa8;
  background-image: linear-gradient(to bottom right, #7a3aa8, #a964da);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  }
  
  .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
  }
  
  .form-group-container {
    display: flex;
    width: 700%;  /* Updated for 7 steps */
    overflow: hidden;
    height: 100%;
}

.form-step {
    width: 14.29%;  /* Updated for 7 steps */
}
  
  .step-subheading {
    font-size: 14px;
    color: #888;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .step-heading {
    margin: 1px;
    color: #330040;
  }

  .language-section {
    margin-top: 1px;
  }

  .tooltip {
    position:relative;
    display:flex;
    cursor: pointer;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 99;
    top: 125%;
    left: 50%;
    margin-left: -125px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  
  .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    margin-top: 20px;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #a964da;
    border-radius: 4px;
  }
  
  .checkbox-container:hover input ~ .checkmark {
    background-color: #e9bbeb;
  }
  
  .checkbox-container input:checked ~ .checkmark {
    background-color: #a964da;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  .crop-mode-btn {
    padding: 10px;
    color: white;
    background:#c7c6c8;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
  }
  
  .text-position-btn,
  .text-style-btn {
    padding: 0px;
    color: white;
    background:#c7c6c8;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
  }
  
  .crop-mode-btn.selected,
  .text-position-btn.selected,
  .text-style-btn.selected {
    background: #550070
  }
  
  .crop-mode-btn:not(.selected):hover,
  .text-position-btn:not(.selected):hover{
    background: #7a3aa8
  }
  
  .option-description {
    color: grey;
    font-size: 0.8em;
    flex-grow: 1; /* Allow the description to fill the remaining width */
    text-align: left;
  }

  .svg-selector {
    display: flex;
    padding: 3px;
    justify-content: space-between;
  }
  
  .svg-selector div {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  
  .svg-description {
    text-align: center;
    font-size: 12px;
    color: rgb(72, 72, 72);
  }
  
  .selected-svg {
    border: 2px solid rgb(96, 15, 96);
    border-radius: 10px; 
    padding: 5px;
    background-color: #ede0f7;
}

.style-selector {
  display: flex;
  justify-content: left;
  width: 100%;
  padding: 5px;
  margin-right: 10px;
}

.text-style-btn {
  align-items: center;
  flex: 1;
  width: 100%;
  margin-right: 10px;
}

.svg-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin: 1px;  /* Reduce the space between the SVGs if necessary */
}

.TopTextPosition, .MiddleTextPosition, .BottomTextPosition {
  width: 40px;  /* Adjust this value as needed */
  height: 40px; /* Adjust this value as needed */
}

.text-size-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
}

.text-size-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  color: #7a3aa8;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 4px;
}

.text-size-slider:hover {
  opacity: 1;
}

.text-size-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #7a3aa8;
  cursor: col-resize;
  border-radius: 50%;
}

.text-size-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  background: none;
  width: 33%;
  border: none;
  cursor: pointer;
  color: #16041a; /* Or whichever color you prefer */
}

.text-size-btn:focus {
  outline: none;
}

.text-size-btn:hover {
  color: #815781; /* Darken the text color slightly when the button is hovered */
}

.text-size-btn.selected-text-size {
  color: rgb(140, 13, 169); /* Highlight the selected text size in purple */
  border: 2px solid rgb(140, 13, 169);
  border-radius: 10px; 
  padding: 1px;
}

.text-size-btn:nth-child(1) {
  font-size: 12px; /* Small */
}

.text-size-btn:nth-child(2) {
  font-size: 20px; /* Medium */
}

.text-size-btn:nth-child(3) {
  font-size: 28px; /* Large */
}

.font-section {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically aligns items if needed */
  width: 100%;
  padding: 10px 0; /* Adds some padding above and below for spacing */
}

.font-label {
  flex: 1; /* Takes up remaining space */
  margin-right: 20px; /* Adds some space between label and FontSelector */
}

  
.back-btn2 {
  font-family: 'Poppins', sans-serif;

  background-color: #d7aef4;
  color: #fff;
  padding: 12px 10px;
  font-size: 16px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 2%;
  width: 100%;
  background-image: linear-gradient(to bottom right, #dfbef7, #cc9bee);
  border: none;
  box-shadow: 0 4px
  6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to top, #ceb6e0, #877097);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  }

  .preview-box {
    background: linear-gradient(to top, #9164b1, #a869d5);
    transition: all 0.5s ease-in-out;
    border: 2px solid #5c0779;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    color: #fafafa;

    
  }
  
  .preview-box-landscape {
    width: 75%;
    height: 50%;
  }
  
  .preview-box-mobile {
    width: 30%;
    height: 50%;
  }
  
  
  .generate-clips-btn:disabled
  {
   background-color: gray;
   cursor: not-allowed;
 }

 .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5c0779;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7a3aa8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.text-style-btn.disabled {
  background-color: grey;
  color: #fff;
}



.tab {
  background: rgba(255, 255, 255, 0.3); /* subtle gradient */
  color: black;
  padding: 10px 15px;
  border-radius: 8px 8px 0 0;
  margin-right: 10px;
  cursor: pointer;
  border: transparent;
  border-bottom: none;
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; /* smooth transitions */
  font-size: 15px;
  flex-shrink: 1;
}
  /* hover effect */
  .tab:hover {
    background: rgb(101, 56, 128);
    transform: scale(1.02); /* slightly enlarges the tab */
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.2);
  }

.tab.active {
  background: linear-gradient(45deg, #440060, #550070); 
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.2); /* more prominent shadow */
  border: 1px solid #330040;
  color: white;
}



.tab-container {
  position: relative;
  display: flex;
  flex-direction: column;  /* Ensure tabs and video are stacked vertically */
  width: 50%;  /* Take full width of parent */
  background-color: #f1f1f1;
  border-color: transparent;
  border-radius: 8px;
  margin-right: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}

.tab-bar {
  display: flex;
  background-color: #f1f1f1;
  border: transparent;
  border-radius: 8px;  
  width: auto;
}


.form-group-special {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
}

.form-group-left {
  flex: 1;
  padding-right: 10px; /* Adjust this value as needed */
}

.form-group-right {
  flex-basis: auto;
}



.style-selector .form-group {
  margin-top: 20px; /* Adjust the value to your preference */
  margin-bottom: 10px;
}

.video-quality-options {
  display: flex;
  gap: 10px; /* Spacing between buttons */
}

.quality-option {
  padding: 10px 20px;
  border: none;
  background-color: #c7c6c8;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quality-option.active {
  background-color: #550070; /* Match with your theme color */
  color: #ffffff;
}

.quality-option:not(.selected):hover
{
    background: #7a3aa8
  }

.chat-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.subtitle-content {
  padding: 0.5rem;
}

.control-bar {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-radius: 8px; /* Adding border-radius for a more modern feel */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
  background-color: #f5f5f5; /* Light gray as base, can be adjusted */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  margin-bottom: 20px;
}

.control-bar:hover {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15); /* Slightly enhanced shadow on hover */
  transform: translateY(-2px); /* Subtle lift on hover */
}

.control-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
}

.control-item label {
  margin-right: 10px; /* Space between label and dropdown/button */
  color: #330040; /* Making it match your color theme */
  font-weight: 600; /* Making label text a bit bold */
}

/* Styling the dropdown for a cleaner look */
.control-item select {
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.control-item select:focus {
  outline: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}


/* You can further style the select dropdown and other elements as needed */
.caption-control-bar {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  border: transparent;
  border-radius: 5px; /* Rounded edges */
  background-color: transparent;
  width: 100%;
}

.caption-control-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth transition for background change */
  border-radius: 4px; /* Rounded edges for each control item */
}

.caption-control-item.selected {
  background-color: #7a3aa8; /* Change this to a shade of purple from your theme */
  color: #ffffff;
}

.caption-control-item:not(.selected):hover {
  background-color: rgba(134, 11, 172, 0.1); /* Light hover effect for non-selected items */
}

.text-style-btn {
  background: none;
  border: none;
  font-size: 14px;
  font: 'Poppins';
  font-weight: bold;
  outline: none;
  cursor: pointer;
  color: inherit; /* It will take the color of its parent i.e. .caption-control-item */
}


.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.position-control-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background-color: transparent; 
}



.position-control-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.position-control-item:hover {
  background-color: rgba(134, 11, 172, 0.1); 
}

.selected-svg {
  color: #860bac;  /* Assuming your SVG reacts to font color changes, if not, you might need additional styling */
}

.text-style-section {
  padding: 8px 8px;
  border-radius: 8px; /* Adding border-radius for a more modern feel */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
  background-color: #f5f5f5; /* Light gray as base, can be adjusted */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  margin-bottom: 20px;
}

.text-style-section:hover {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15); /* Slightly enhanced shadow on hover */
  transform: translateY(-2px); /* Subtle lift on hover */
}





@media (max-width: 700px) {
  .clip-settings-content{
    height: 100%;
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    width: 100%;
  }

  .form-container {
    width: 100%;
  }

}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.preset-container {
  position: relative;
  
}

.preset-button {
  font-family: 'Poppins', 'san-seriff';
  font-weight: bold;
  cursor: pointer;
  border: 1px transparent;
  padding: 8px 10px;
  background: #ffffff;
  color: rgb(63, 6, 104) ;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.preset-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  width: 100%;
  border: 1px solid white;
  background: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.preset-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  color: #330040;
}

.preset-dropdown ul li {
  padding: 4px;
  cursor: pointer;
}


.preset-save-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 20px;
}

.preset-save-checkbox {
  margin-right: 8px;
}

.close-preset-name {
  position: absolute;
  top: 2px;
  right: 2px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.preset-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
  background-color: #32143b;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);

  color: white;
  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
  position: relative;
  backdrop-filter: blur(5px);
  animation: fadein 0.5s; /* Fade-in animation */
}

.preset-input {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid white;
  border-radius: 10px;
}

.preset-confirm-button {
  font-family: 'Poppins', sans-serif;
  width: 40%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #882fb8;
  color: white;
  cursor: pointer;
}

/* Fade-in animation */
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.crop-mode-container {
  position: relative;
}

.hover-image-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1001; 
}

.hover-image-container img {
  height: 100%;
  width: auto;
  z-index: 1001;
}



