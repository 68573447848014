/* SettingsAccordion.css */

.AccordionWrapper {
  border: 10px transparent;
  border-radius: 5px;
  margin-bottom: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.AccordionHeader {
  padding: 10px 15px;
  cursor: pointer;
  background-color: #ffffff;
  color: #380245;
  font-weight: 500;
  border: transparent;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
}

.AccordionHeader:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AccordionContent {
  overflow: hidden;
  max-height: 4px; /* Adjusted for the padding value */
  transition: max-height 0.3s ease;
  padding: 2px 20px; /* Moved outside the .open class */
  box-sizing: border-box; /* Ensure padding is included in height calculation */
}

.AccordionContent.open {
  max-height: 800px;
}

.AccordionHeader .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.switch {
  margin-left: auto;
}
