@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');


.newssection{
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: bold;
}


.rounded-button-link {
    text-decoration: none;
  }
  
  .rounded-button {
    background: linear-gradient(45deg, #882db3, #ee71e8);
    border-radius: 25px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    width: 400px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    margin-top: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 1;
    position: relative;
    overflow: hidden;
  }
  
  .rounded-button:hover {
    background: linear-gradient(45deg, #dc5ce0, #2e0f6b);
    transform: scale(1.1);
  }
  
  .rounded-button-plus {
    font-size: 3rem;
    margin-top: 0.5rem;
    animation: pulse 1.5s infinite;
  }
  
  .additional-text {
    max-width: 400px;
    text-align: right;
    position: absolute;
    right: 2rem;
    top: 45rem;
    z-index: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .rounded-button-logo {
    width: 30rem;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .additional-text::before {
    content: '';
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    transform: rotate(135deg);
    background-size: 200px 200px;
    z-index: -1;
    font-family: 'Poppins', sans-serif;
  }
  
  .additional-text h2 {
    color: #dcd0f8;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .additional-text p {
    color: #dcd0f8;
    font-size: 1.5rem;
  }