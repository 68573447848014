.landing1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background-image: linear-gradient(135deg, #320b41 0%, #4d185e 100%);
  z-index: 0; /* Add this line to ensure the z-index layering order */
  
}

.landing1::before, .landing1::after {
  content: '';
  position: fixed;
  width: 200px; /* Adjust based on desired blur size. */
  height: 200px; /* Adjust based on desired blur size. */
  border-radius: 50%; /* Make it circular. */
  background: radial-gradient(circle, rgba(141, 14, 141, 0.6), rgba(118, 18, 136, 0.8)); /* Two shades of purple. */
  filter: blur(50px); /* Add blur. */
  z-index: -1; /* Ensure blurs appear behind any other content. */
  animation: pulse 1s infinite;
}

.landing1::before {
  top: calc(4%); /* Center it vertically, adjust based on blur size. */
  left: calc(10% - 100px); /* Center it horizontally, adjust based on blur size. */

}

.landing1::after {
  top: calc(40%);
  left: calc(80%);
  background: radial-gradient(circle, rgba(170, 3, 170, 0.6), rgba(86, 3, 145, 0.8));
  /* Two other shades of purple. */

}

.dashboard-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 20px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(9.5);
  }
  100% {
    transform: scale(1);
  }
}

header {
  width: 100%;
  font-size: 24px;  /* Adjust as per your design */
  font-weight: bold;
  margin-top: 5px;
  text-align: center;  /* Center the header text */
  color:#ede9f5;
  background: transparent;
}


.upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  background-color: transparent;
  border: transparent;
  border-radius: 15px;
  
  box-sizing: border-box;
  margin-top: 5px;
}

.upload-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 16px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}


.upload-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 8px;
  background-color: #dcd0f827;
  transition: all 0.3s ease;
  font-weight: bold; 
}

.upload-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  transform: scale(1.05);
}



.upload-thumbnail {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.upload-filename {
  margin-top: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #dcd0f8;
}

.upload-button {
  font-weight: bold;
  margin-top: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0;
  background-color: #f5f5f500;
  
}

.upload-icon {
  font-size: 48px;
  color:#dcd0f8;
}


.upload-item.clips-generated {
  background-color: #1f0034;
  color: #e1daf0;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.upload-item.first-upload-item {
  color: #e1daf0;
  background-color: transparent;
  box-shadow: none;
}

.clips-generated-text {
  font-size: 12px;
  font-weight: bold;
  color: rgb(106, 211, 90);
  margin-top: 4px;
}

.processing {
  border: 2px solid #76767602;
  animation: pulsate 1.5s infinite;
  background-color: #f9f9f96f;
}

.processing-text {
  font-size: 12px;
  font-weight: bold;
  color: rgb(196, 189, 0);
  margin-top: 4px;
}

@keyframes pulsate {
  0% { box-shadow: 0 0 5px rgb(163, 144, 170); }
  50% { box-shadow: 0 0 20px rgba(163, 144, 170, 0.584), 0 0 30px rgba(163, 144, 170, 0.478); }
  100% { box-shadow: 0 0 5px rgb(163, 144, 170); }
}


.progress-container {
  width: 100%;
  background-color: #e1daf0;
  margin-top: 20px;
  border-radius: 4px;
}

.progress-bar {
  background-color: #2f80ed;
  height: 20px;
  border-radius: 4px;
}


.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  height: 30rem;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(177, 44, 214, 0.1);
  width: 20rem; /* Update this value to match the logo size */
  height: 20rem; /* Update this value to match the logo size */
  border-radius: 50%;
  border-left-color: rgb(72, 20, 120);
  animation: spin 1s linear infinite;
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.pulsing-logo {
  width: 30%;
  height: 30%;
  max-width: 100px;
  max-height: 100px;
  animation: pulse 1s infinite;
  z-index: 2;
  position: absolute;
  top: calc(50% - 35px); /* Subtract a value to move the logo a little bit higher */
  left: calc(50% - 40px);
  transform: translate(-50%, -50%);
}


@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading-text {
  color: #ffffff;
  position: absolute;
  bottom: 10%;
  text-align: center;
  font-size: 1.125rem;
  z-index: 2;
}

.status-tabs {
  display: flex;
  justify-content: space-between;
  background-color:transparent;
  border: transparent;
  border-radius: 15px;
  
  padding: 5px;
  margin-top: 10px;
  width: 50%;
}

.status-tabs button {
  flex: 1;
  text-align: center;
  padding: 10px 10px;
  background-color:#1f0034;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  color: #e1daf0;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-right: 8px;
}

.status-tabs button.selected {
  background-color: #510087;
  color: #e1daf0;
  border: 2px solid #b19cd9; /* light purple color */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
}

.status-tabs button:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .status-tabs {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .status-tabs button {
    flex: none; /* Disable flex scaling for small screens */
    margin-bottom: 8px; /* Add some vertical spacing between buttons */
  }
}