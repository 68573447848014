.login-container {
  background-color: #F4F4F4;
  height: 100vh;
}

.login-form-container {
  background-color: #FFFFFF;
  margin: 0 auto;
  max-width: 400px;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.login-input {
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 16px;
  font-size: 14px;
}

.login-button {
  background-color: #a964da;
  border: none;
  border-radius: 10px; /* rounded corners */
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
  transition: background-color 0.3s ease-in-out;
}

.login-button:hover {
  background-color: #9254b9; /* less dramatic change */
  cursor: pointer;
}

.forgot-password-link {
  display: inline-block;
  margin-top: 20px;
  color: #65129f; /* Same as the button color */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.forgot-password-link:hover {
  color: #380453; /* Same as the button hover color */
  text-decoration: underline;
}
.google-signin-container {
  display: flex;
  justify-content: center;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #626161;
}

.separator hr {
  flex: 1;
  border: none;
  height: 1px;
  background: #434343;
}

.separator span {
  padding: 0 10px;
}

.memo {
  font-size: 12px;
  display: inline-block;
  margin-top: 10px;
  color: #908d92; /* Same as the button color */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}