@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1f0034eb;
  backdrop-filter: blur(5px);
  height: 70px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  padding: 0 20px;
  z-index: 2;
  position: sticky;
  top: 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.55);
}

.navbar-nav {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-item {
  list-style-type: none;
  margin-right: 30px;
  transition: all 0.3s ease;
}

.nav-item:hover {
  transform: translateY(-2px);
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.logo-image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: 5px;
}



.nav-link {
  color: #d9cff1;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  border-radius: 5px;
}

.nav-link:hover {
  background-color: rgb(224, 102, 210);
  border-radius: 5px;
}

.nav-right-links {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-link-signup {
  color: #fff;
  background-color: #8853E6;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.nav-link-signup:hover {
  background-color: #6d3ce3;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.nav-link-signup:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-image: linear-gradient(135deg, transparent 0%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 100%);
  background-size: 200% 200%;
  transition: all 0.3s ease-in-out;
}

.nav-link-signup:hover:before {
  background-position: 100% 100%;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 18px;
  width: 25px;
  cursor: pointer;
}

.hamburger-line {
  height: 3px;
  width: 100%;
  background-color: #d9cff1;
  border-radius: 3px;
}



.usagebar {
  height: 100%;
  background-color: rgb(224, 102, 210);
  border-radius: 7px;
}


.usage-text {
  margin-left: 10px;
  color: #d9cff1;
  size: 5pt;
}


.usage2-container {
  display: flex; /* Ensure elements are in a row */
  align-items: center;
  width: 100%;
  position: relative;
}

.usage-icon {
  display: flex; /* Align the icon and text */
  align-items: center;
  cursor: pointer;
  margin-right: 10px; /* Space between the icon and the usage bar */
}

.usage-info-text {
  display: none; /* Hide by default */
}

.usage-icon:hover .usage-info-text {
  display: block; /* Show when the icon is hovered over */
}

.usagehold {
  height: 15px;
  width: 30%;
  background-color: #f0ecf7;
  border-radius: 8px;
  border: 1px transparent;
  overflow: hidden;
  margin: 10px 0;
}


.usage2-container {
  display: flex;
  align-items: center;
}

.usage-icon {
  font-size: 24px; /* Increase the size of the icon */
  cursor: pointer;
  margin-right: 10px;
  color: #d9cff1;
}

.usagehold {
  height: 15px;
  width: 30%;
  background-color: #d9cff1;
  border-radius: 8px;
  border: 1px transparent;
  overflow: hidden;
}

.usage-info-text {
  display: none; /* Hide by default */
  margin-left: 10px; /* Space between the bar and text */
  color: #d9cff1;
  font-size: 16px; /* Adjust font size as needed */
}

.usage2-container:hover .usage-info-text {
  display: block; /* Show when the container is hovered over */
}

.usagebar:hover .usage-info-text {
  display: block; /* Show when the container is hovered over */
}


.usage2-container {
  display: flex;
  align-items: center;
  
}



.upgrade-link {
  color: #ffea30; /* Color for the link */
  text-decoration: underline; /* Underlined text for the link */
  font-size: 12pt;
  font-weight: bold;
}

.usage2-container:hover .usage-info-text {
  display: block; /* Show when the container is hovered over */
}


@media (max-width: 767px) {
  .navbar {
    z-index: 1001;
  }

  .navbar-nav {
    position: fixed;
    top: -100vh; /* Move it entirely out of the viewport */
    right: 0;
    max-width: 200px;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #320b41;
    padding: 20px;
    transform: translateY(0);
    transition: top 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 100;
    margin-left: auto;
  }
  
  .navbar-nav.show {
    top: 3.2rem; /* Move it back into view */
  }

  .nav-item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .nav-right-links {
    flex-direction: column;
    align-items: flex-end;
  }

  .hamburger {
    display: flex;
    z-index: 100;
  }
}


