.faq-section {
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .faq-title {
    color: #e3dbf5;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
  }
  
  .faq-item {
    max-width: 800px;
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    background-color: transparent;
    border-bottom: 6px solid rgb(224, 102, 210);
    border-radius: 0px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative; /* Required for positioning the arrow */
  }
  
  .faq-question {
    background-color: transparent;
    font-size: 20px;
    color: #e3dbf5;
    margin-bottom: 10px;
    font-weight: bold;
    display: flex; /* Flexbox layout to align the arrow and question */
    justify-content: space-between; /* Space between question and arrow */
  }
  
  .faq-answer {
    background-color: transparent;
    font-size: 18px;
    color: #e3dbf5;
    line-height: 1.6;
    display: none;
    font-weight: bold;
  }
  
  .faq-item.faq-open .faq-answer {
    display: block;
  }
  
  .arrow.up {
    /* Add your styles for the up arrow */
  }
  
  .arrow.down {
    /* Add your styles for the down arrow */
  }
  