.news-slide {
    display: flex !important;
    flex-direction: row; /* Horizontal layout */
    background-color: transparent;
    color: #e3dbf5;
    border-radius: 10px;
    align-items: center;
    padding: 1rem;
    text-align: left; /* Align text to the left */
    width: 80%;
  }
  
  .news-image {
    width: 400px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    flex-shrink: 0; /* Prevent shrinking in flex container */
  }
  
  
  .news-content {
    flex: 1; /* Take up half of the space */
    padding-left: 3rem;
  }
  
  .news-content h3 {
    font-size: 55px; /* Increased font size */
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .news-content p {
    font-size: 24px; /* Increased font size */
  }
  