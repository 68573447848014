.outer-usage-container {
    display: flex;
    flex-direction: row;
    background-color: #320b41;
  }
  
  .usage-container {
    padding: 20px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(135deg, #320b41 0%, #4d185e 100%);
    color: rgb(181, 181, 181);
  }
  


  .sidebar-toggle {
    display: none;
  }

  @media screen and (max-width: 768px) {
    
    .profile-sidebar {
      transform: translateX(0);
    }
  
    .profile-sidebar {
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }
  
    .profile-sidebar.open {
      transform: translateX(0);
    }
  
    .sidebar-toggle {
      display: block;
    }
  }