/* MarkdownStyles.css */
.markdown-body {
    font-family: 'Poppins', sans-serif; /* Choose a nice, readable font */
    line-height: 1.6;
  }
  
  .markdown-body h1, 
  .markdown-body h2, 
  .markdown-body h3 {
    color: #e066d2; /* Your pink accent color for headers */
    padding-bottom: 0.3em; /* Space under headers */
  }
  
  .markdown-body p {
    margin: 1em 0; /* Space between paragraphs */
  }
  
  .markdown-body a {
    color: #ffffff; /* Your light purple text color */
    text-decoration: underline; /* So links are clear */
  }

  .markdown-body img{
    width: 100%;
    height: auto;
  }
  
  .markdown-body code {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    background-color: #2e003e; /* A darker purple for contrast */
    color: #ffffff; /* Keeping the light text */
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  .markdown-body blockquote {
    margin-left: 0;
    padding-left: 1em;
    color: #cecece; /* Lighter text color for contrast */
    border-left: 4px solid #e066d2; /* A border with your pink accent color */
    font-style: italic;
  }
  