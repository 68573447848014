.walkthrough-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-container {
  flex: 1;
  transform: translateX(62%);
  margin-right: 0%;
}

/* CSS */
.left-container {
  position: relative;
  /* or absolute, based on your need */
  z-index: 1;
  /* Any value higher than the z-index of the Welcome component */
}


.right-container {
  flex: 1;
  padding: 20px;
  transform: translateX(100%);
  opacity: 0;
}

.section-title {
  font-size: 2.8em;
  color: rgb(19, 2, 48);
  margin-bottom: 20px;
  font-weight: bolder;
  /* Title is now bold */
}

.highlight {
  color: rgb(224, 102, 210);
  font-weight: bold;
}

.computer-window {
  border-radius: 5px;
}

.video-container {
  width: 100%;
  height: 100%;
  z-index: 3;
}



.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1f0034;
  /* Adjust this as necessary */
  padding: 5px;
  /* Optional: Add a border */
  border-radius: 50px;
}

.tab2 {
  padding: 10px 15px;
  /* Adjust padding as necessary */
  cursor: pointer;
  color: rgb(224, 102, 210);
  font-weight: bold;
  background-color: rgb(19, 2, 48);
  /* Adjust this to your desired inactive tab color */
  border-radius: 45px;
  /* Adjust for rounded corners on the tabs */

}

.tab2.active {
  background-color: rgb(224, 102, 210);
  /* Active tab highlighted in pink */
  color: rgb(19, 2, 48);
  border-radius: 45px;
}

.tab-description {
  font-size: 1.5em;
  color: rgb(224, 102, 210);
  font-weight: bold;
  margin-top: 20px;
}

/* Mobile Styles (up to 768px) */
@media (max-width: 768px) {

  .walkthrough-container {
    flex-direction: column;
    /* Stack content vertically on mobile */
  }

  .left-container,
  .right-container {
    flex: unset;
    transform: none;
    /* Reset transform styles for mobile */
    width: 100%;
    /* Use full width */
    margin: 0;
    padding: 10px 0;
  }

  .section-title {
    font-size: 1.8em;
    /* Decrease font size to fit mobile screens */
    text-align: center;
    /* Center the title on mobile for better presentation */
    margin-top: 10px;
    /* Provide a bit of space at the top */
  }


  .tab-description {
    font-size: 1.2em;
    /* Decrease font size for mobile */
    margin-top: 10px;
    text-align: center;
    /* Center the description for a cleaner look on mobile */
  }

}

/* Mobile Styles (up to 768px) */
@media (max-width: 768px) {

  /* ... [other styles from before] ... */

  .tabs {
    flex-direction: column;     /* Stack tabs vertically */
    align-items: center;        /* Center tabs horizontally */
    justify-content: center;   /* Center tabs vertically if there's extra space */
    background-color: #1f0034;
  }

  .tab2 {
    flex: 1;                    /* Each tab will grow to share equal space */
    text-align: center;         /* Center text within each tab */
    margin: 5px 0;              /* A little space between tabs */
    width: 70%;                 /* Set the width of each tab */
    background-color: #1f0034;
  }
}


/* Mobile Styles (up to 768px) */
@media (max-width: 768px) {

  /* ... [previous styles] ... */

  .computer-window {
    width: 100%;
    /* Reduce the width of the computer-window to fit better */
    max-height: 250px;
    /* Limit the height of the SVG */
    margin: 0 auto;
    /* Center the SVG */
    display: block;
    /* To ensure margin auto works as expected */
  }



  /* Adjustments for the right-container */
  .right-container {
    padding: 10px;
    width: 100%;
    color: rgb(224, 102, 210);
  }

  /* If text still feels large or congested */
  .tab-description,
  .section-title,
  .tab2 {
    line-height: 1.3;
    /* Adjust line height for better spacing between lines */
  }
}


.logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-image: url('./LogoPhoto.jpg');*/
  background-image: url('./ImageComputer2.png');
  /* Correct the path */
  background-size: cover;
  background-position: center;
  opacity: 1;
  /* Initially fully visible */
  transition: opacity ease-in-out;
  /* Optional: Smooth transition effect */
  z-index: 2;
  /* Ensure it's above the video but below other content */
}

@media (max-width: 768px) {
  .logo-overlay {
    display: none;
  }

  .computer-window {
    width: 100% !important;  
    height: auto !important;
  }

  
}

/* On mobile */
@media (max-width: 768px) {
  .video-foreign-object {
    width: 100% !important;
    height: 100% !important;
  }

  .computer-window {
    width: 100% !important;
    height: 100% !important;
  }
  
  .video-container video {
    width: 100% !important;
    height: 100% !important;
  }
}



/* On mobile */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

    .video-foreign-object {
        width: 90vw !important;  /* Adjust these values as required */
        height: 50vh !important;
    }

    .computer-window {
        width: 90vw !important;
        height: 50vh !important;
    }

    .landing-video-container video {
        width: 90vw !important;
        height: 25vh !important;
    }
}

/* Target iPads */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .video-foreign-object {
        width: 70vw !important;  /* Adjust these values as required */
        height: 40vh !important;
    }

    .computer-window {
        width: 70vw !important;
        height: 40vh !important;
    }

    .landing-video-container video {
        width: 70vw !important;
        height: 40vh !important;
    }
}



