.forgot-password-container {
    background-color: #F4F4F4;
    height: 100vh;
  }
  
  .forgot-password-form-container {
    background-color: #FFFFFF;
    margin: 0 auto;
    max-width: 400px;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .forgot-password-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .forgot-password-input {
    border: 1px solid #E1E1E1;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 16px;
    font-size: 14px;
  }
  
  .forgot-password-button {
    background-color: #a964da;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .forgot-password-button:hover {
    background-color: #9254b9;
    cursor: pointer;
  }
  