.profile-container {
    color: rgb(181, 181, 181);
    height: 100vh;
}

.sidebar-toggle {
    display: none;
  }

  /* Add these classes */
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #320b41;
  }
  
  .prof-container {
    height: 100vh;
    background-color: #320b4100;
    color: rgb(221, 221, 221);
  }
  
  .sidebar-toggle {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .profile-sidebar {
      transform: translateX(0);
    }
  
    .profile-sidebar {
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }
  
    .profile-sidebar.open {
      transform: translateX(0);
    }
  
    .profile-container {
      flex-direction: column;
    }
  
    .prof-container {
      height: auto;
      padding-bottom: 20px;
    }
  
    .sidebar-toggle {
      display: block;
    }
  }
  
  