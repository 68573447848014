body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 0.9);
}

body::before {
  content: "";
  position: fixed;  /* Fixed position */
  top: 0;  /* At the top */
  left: 0;  /* At the left */
  right: 0;  /* At the right */
  height: 70px;  /* 10% of viewport height */
  background-color: rgba(56, 2, 69);  /* Your purple color */
  z-index: -1;  /* Place it below the content */
  width: 100%;  /* Full width */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat.ttf') format('truetype');
}

@font-face {
  font-family: 'Hachicro';
  src: url('./fonts/Hachicro.ttf') format('truetype');
}

@font-face {
  font-family: 'AppleTea';
  src: url('./fonts/AppleTea.ttf') format('truetype');
}

@font-face {
  font-family: 'Kaph';
  src: url('./fonts/Kaph.ttf') format('truetype');
}

@font-face {
  font-family: 'Muela';
  src: url('./fonts/Muela.ttf') format('truetype');
}

@font-face {
  font-family: 'PeaceSans';
  src: url('./fonts/PeaceSans.ttf') format('truetype');
}

@font-face {
  font-family: 'PoetsenOne';
  src: url('./fonts/PoetsenOne.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppin';
  src: url('./fonts/Poppin.ttf') format('truetype');
}

@font-face {
  font-family: 'PTF';
  src: url('./fonts/PTF.ttf') format('truetype');
}

