@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');


h1 {
  text-align: center;
  width: 100%;
  margin: 10px 0;
  font-weight: bold;
}

.disabled-button:before {
  content: "🔒 Upgrade";
  position: absolute;
  background-color: rgba(0,0,0,0.7);
  color: white;
  padding: 5px;
  border-radius: 5px;
  left: 100%;
  top: 50%;
  white-space: nowrap;
  visibility: hidden;
  transform: translateY(-50%);
}

.disabled-button:hover:before {
  visibility: visible;
}


/* styles.css */
.gen-clips {
  transition: all 0.3s ease;
  padding-top: 0;
  height: 105vh;
}
.gen-clips.with-banner {
  padding-top: 50px; /* Adjust to match the height of your banner */
}


.generated-clips-container {
  width: 30%;
  height: 90vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: #fffdfd;
  border: 3px transparent;
  /* optional: adds a separating line */

  border-radius: 5px 0 0 0;
}

/* styles.css */
.free-subscription-banner {
  background-color: #14002df5;
  color: #e3dbf5;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  z-index: 1000;
  position: fixed;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

}

.free-subscription-banner a {
  color: yellow;
}

/* styles.css */
.free-subscription-banner {
  /* ...existing styles */
  transition: all 0.3s ease;
}

/* styles.css */
.close-banner-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff; /* or whatever contrasts with your banner background */
}



.generated-clips-container {
  direction: rtl;
  /* moves the scrollbar to the left */
  overflow-y: scroll;
  /* assuming you want a vertical scrollbar */
}

.generated-clips-container>* {
  direction: ltr;
  /* resets the direction for child elements */
}

.clips-grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  background-color: transparent;
  box-sizing: border-box;
}



h2 {
  color: #333;
  margin-bottom: 10px;
  align-self: left;
}

.clip-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  height: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border: 2px solid #ede2f3;
  /* optional: adds a separating line */
  border-radius: 5px;
  background-color: #f6f5f9;
  transition: all 0.3s ease;
}

.clip-container:hover {
  transform: scale(1.02);
  /* slightly increase size on hover */
  border: 3px solid rgb(233, 207, 243);
}

.clip-container:active:not(.clip-star) {
  transform: scale(0.98);
  /* slightly decrease size on click */
}

.clip-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  /* Replace with whatever height you want */
  width: 50%;
  /* Or replace with the specific width you want */
}


.clipName {
  font-size: 10pt;
  margin-left: 10px;
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
  display: -webkit-box;
  /* Enables -webkit-line-clamp property */
  -webkit-line-clamp: 3;
  /* Limits the text to 3 lines */
  -webkit-box-orient: vertical;
  /* Necessary to make line-clamp work */
  overflow: hidden;
  /* Hides the text that overflows the container */
  text-overflow: ellipsis;
  /* Adds an ellipsis (...) at the end of the truncated text */
  max-width: 100%;
  /* Makes sure the text doesn't exceed the container's width */
}



.clip-title {
  color: #333;
  margin-bottom: 10px;
}

.clip-thumbnail {
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.clip-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;


  font-weight: bold;
  font-size: 11pt;
}

.clip-details p {
  margin: 0;
  padding: 0;
  font-size: 10pt;
}





.clip-video {
  border-radius: 5px;
  width: 70%;
  border: 3px solid #49056c;
  border-radius: 15px;
}

.clip-caption {
  margin-top: 10px;
  width: 100%;
  height: 60px;
  resize: none;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.clip-editor {
  width: 70%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffdfd;

}

.empty-editor {
  text-align: center;
  color: #999;
}

.gen-clips {
  display: flex;
  justify-content: flex-start;

}

.clip-container.selected {
  border: 3px solid #1f0034;
  background: #1f0034;
  color: #f5f5f5;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.editor-content {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.search-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  height: 20px;


  border-radius: 5px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #f6f5f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.search-input {
  width: 80%;
  padding: 0.25rem;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Poppins', sans-serif;
  /* Specify your font here */
  color: #1f0034;
}

.search-button {
  width: 40px;
  /* Adjust this value as needed to accommodate the icon */
  height: 40px;
  /* Adjust this value as needed to accommodate the icon */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f0034;
  color: #fff;
  border: none;
  border-radius: 50%;
  /* Makes the button circular */
  cursor: pointer;
}

.search-button:hover {
  background-color: rgb(83, 4, 111);
}

.search-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}


.video-player {
  width: 58%;
  height: 100%;
  padding: 2rem;
  padding-bottom: 5rem;
  padding-top: 0rem;

}

.video-player video {
  width: 55%;
  border: 3px transparent;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

}

.clip-options {
  width: 40%;
  /* Set this to whatever width you prefer */
  height: 100%;

}

.clip-options {
  background: transparent;
  border: 3px transparent;
  border-radius: 5px;
  padding: 1rem;
  box-sizing: border-box;


}

.clip-options input[type="text"],
.clip-options textarea {
  resize: none;
  width: 100%;
  max-height: 10%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}


.option-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

}

.share-options {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
}

.upload-container {
  width: 100%;
  /* Additional styling as needed */
}

.youtube-upload-container,
.tiktok-upload-container {
  width: 100%;
  /* Additional styling as needed */
}


.edit-button {
  padding: 10px;
  color: white;
  background: #1f0034;
  border: none;
  border-radius: 4px;
  /* Rounded corners */
  cursor: pointer;
}

.clip-options button {
  background-color: #1f0034;
  color: #fff;
  padding: 1rem;
  margin: 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  width: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 12px;
}

.clip-options button:hover {
  background-color: #5e26ad;
}

.edit-button:disabled,
.tiktok-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner2 {
  margin: 0 auto;
  border: 16px solid #f3f3f3;
  border-top: 16px solid rgb(88, 29, 128);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.tiktok-upload-container {
  max-width: 100%;
  /* Set max-width to fit the screen */
  height: 100%;
  margin: 0 auto;
  /* Center horizontally */
  overflow: auto;
  /* Enable scroll if content is too wide */
  /* Add other styling as needed */
  padding: 1rem;
}

.youtube-upload-container {
  max-width: 100%;
  /* Set max-width to fit the screen */
  height: 100%;
  margin: 0 auto;
  /* Center horizontally */
  overflow: auto;
  /* Enable scroll if content is too wide */
  /* Add other styling as needed */
  padding: 1rem;
}

.youtube-upload-container.inactive,
.tiktok-upload-container.inactive {
  display: none;
  /* Hide inactive container */
}

.youtube-upload-container.active,
.tiktok-upload-container.active {
  display: block;
  /* Display active container */
  width: 100%;
  /* Additional styling as needed */
}

.search-tags {
  display: flex;
  justify-content: center;
  /* Center align the buttons */
  gap: 5px;
  margin: 10px 20px;
  /* Add margins around the container to prevent touching the edges */
  flex-wrap: wrap;
}

.tag-button {
  border: 3px solid #785092;
  background-color: #c19cdb;
  color: #350158;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.tag-button:hover {
  background-color: rgb(80, 10, 90);
  border-color: rgb(33, 3, 37);
  color: #ede2f3;
}






.clip-info,
.clip-details {
  box-sizing: border-box;
}

.clip-content {
  display: flex;
  flex-direction: row;
  /* Changed from column to row */
  justify-content: space-between;
  /* Pushes children to opposite ends */
  align-items: flex-start;
  /* Aligns items to the top */
  box-sizing: border-box;
}

.clip-details p:not(:last-child) {
  margin-bottom: 20px;
  /* Add some bottom margin to all <p> elements except the last one */
}

.clip-details {
  margin-left: auto;
  /* Pushes the div to the far right */
  /* ... other styles ... */
}

.clip-content {
  width: 100%;
}

.clip-details {
  margin-top: 10px;
}







/* Mobile view - Styles apply for screens up to 600px wide. Adjust as needed */
@media (max-width: 700px) {

    .gen-clips {
      height: 120%; /* let it grow as needed */
      flex-direction: column;
      align-items: center;
    }
  
    .generated-clips-container,
    .clip-editor {
      width: 90%;
    }
  
    .clip-editor {
      margin-left: 1px;
      margin-top: 2rem; /* Add some space between clip-editor and the container above */
    }
  
    .generated-clips-container {
      height: auto;  /* let it grow as needed */
      max-height: 20rem; /* set a maximum height */
      overflow-y: auto;  /* make it scrollable */
    }
  
    .clip-container {
      z-index: 2;  /* Make it positive or zero, so it doesn't hide behind other elements */
      height: auto;  /* let it grow as needed */
      max-height: 5rem; /* set a maximum height */
      flex-direction: column;
      align-items: center;

    }
  
    /* ... rest of your styles */
  
  

  .clip-info,
  .clip-details {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .editor-content {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .video-player,
  .clip-options {
    width: 100%;
  }

  .video-player video {
    width: 100%;
  }
}

.search-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-spinner div {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #333;

  border-radius: 50%;
  display: inline-block;
  animation: pulse 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.search-spinner div:nth-child(1) {
  animation-delay: -0.3s;
}

.search-spinner div:nth-child(2) {
  animation-delay: -0.15s;
}

@keyframes pulse {

  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

.title,
.caption {
  font-family: 'Poppins', sans-serif;
  color: #560056;
}


/* Default star style */
.clip-star {
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  padding: 5px;
  
  /* Increase the clickable area */
  box-sizing: content-box;
  /* Ensures padding doesn't shrink the icon */
  /* Default to white */
  cursor: pointer;
  transition: color 0.3s;
  /* Adjust as needed */
  opacity: 0;
  /* Hide the star by default */
  transition: opacity 0.15s;
  /* Smooth transition for showing/hiding */
  z-index: 1000;
}

.clip-container:hover .clip-star {
  opacity: 1;
  /* Show the star */
  color: #000;
  /* White outline for non-filled star */
}

.clip-container:hover .clip-star.fa-star {
  opacity: 1;
  /* Show the star */
  color: #FFD700;
  /* White outline for non-filled star */
}

.clip-star.fa-star {
  opacity: 1;
  /* Always show the filled star */
  color: #FFD700;
  /* Gold color for filled star */
}

/* Star hover and filled star style */
.clip-star:hover,
.clip-star.fa-star {
  color: #FFD700;
  /* gold color on hover and when starred */
}

/* Star style for selected clip */
.clip-container.selected .clip-star {
  color: #FFF;
  /* white outline for non-filled star in selected clip */
}

.clip-container.selected .clip-star.fa-star {
  color: #FFD700;
  /* gold color for filled star in selected clip */
}

@keyframes clickStar {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.clip-star:active {
  animation: clickStar 0.2s forwards;
}

