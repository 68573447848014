@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.upload-container1 {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center; /* Change from flex-start to center */
  min-height: 0; /* Add this to override the height: 100% */
  padding: 20px;
}

.upload-form-container {
  background-color: #ffffff;
  border-radius: 8px;
  border: 4px solid #7e00cd;
  padding: 40px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.4);
  /* Add a max-width to prevent overflowing the modal */
  max-width: 100%;
}


.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-file-upload {
  border: 1px solid #A964D8;
  display: inline-block;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 14px;
  color: #A964D8;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 24px;
  margin-bottom: 20px;
}

.custom-file-upload:hover {
  background-color: #A964D8;
  color: #fff;
}

#file-upload {
  display: none;
}

.youtube-link-input {
  width: 100%;
  border: 1px solid #A964D8;
  border-radius: 24px;
  padding: 12px 20px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  outline: none;
  margin-bottom: 20px;
}

.youtube-link-input::placeholder {
  color: #A964D8;
  opacity: 0.6;
  text-align: center;
}

.youtube-link-input:focus {
  border-color: #8853E6;
  box-shadow: 0 0 0 2px rgba(168, 100, 216, 0.2);
}

.upload-btn {
  background: linear-gradient(to right, #A964D8, #763a9e);
  color: #fff;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.upload-btn:hover {
  background: linear-gradient(to right, rgba(90, 14, 128, 0.8), rgba(60, 8, 98, 0.8));
}


.selected-file-name {
  font-weight: bold;
  text-align: center;
}

.upload-progress-container {
  width: 100%;
  background-color: rgba(201, 129, 196, 0.5); /* Darker purple for contrast */
  height: 50px;
  position: relative;
  border-radius: 25px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  overflow: hidden; /* To make sure inner content respects border-radius */
}

.upload-progress-bar {
  width: 0%;
  height: 100%;
  background: linear-gradient(to right, rgba(228, 26, 228, 0.6), rgba(90, 14, 128, 0.8));
  border-radius: 25px;
  transition: width 0.5s ease-in-out;
  animation: upload-pulse 2s infinite linear;
}

@keyframes upload-pulse {
  0% { 
    background-position: 100% 0;
  }
  100% { 
    background-position: 0 0;
  }
}

.progress-auxiliary-text {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s;
}
