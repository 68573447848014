@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  height: 100vh;
}

h1 {
  text-align: center;
  font-weight: bold;
}

.view-generated-clips {
  background-color: rgb(159,103,211);
  color: #fff;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  text-decoration: none;
}

.view-generated-clips:hover {
  background-color: #7a3aa8;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.processing_status_bar {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.processing_status_bar li {
  background-color: #EEE;
  color: black;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.processing_status_bar .completed {
  background-color: rgb(68, 23, 111);
  color: white;
}

.processing_status_bar .inProgress {
  background-color: rgb(148, 87, 202);
  color: rgb(255, 255, 255);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.estimated-time {
  margin-top: 20px;
  font-size: 18px;
  color: #7a3aa8; /* Golden for visibility */
  text-align: center;
}

.fa-clock-o {
  margin-right: 8px; /* Add some spacing between the icon and the text */
  vertical-align: middle; /* Align it properly with the text */
  
}

.fa-clock-o {
  font-size: 24px;  /* Adjust to the size you want */ /* Optional: adds some space between the icon and the text */
}

.discord-section {
  margin-top: 150px;
  font-weight: bold;
  color: #2a0743;
}
